import React from "react";
import HowitWorksComponent from "../../components/HowItWorks";
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import NavbarMobile from "../../components/NavbarMobile";
import Navbar from "../../components/NavbarDesktop";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ShareComponent from "../../components/ShareComponent";

function HowItWorks () {
    const token = localStorage.getItem('jwt')
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    return(
        <div>
            {token ?
            (desktop ? <NavBarAuth/> : <NavbarMobileAuth/>) :
            (desktop ? <Navbar/> : <NavbarMobile/>)
            }
            
            <HowitWorksComponent step='Step 1. Search for subjects that you need help with' text = 'Browse available student tutors in your school district and the subject you’re looking for.'/>
            <HowitWorksComponent step='Step 2. Get introduced' text = 'Connect with student tutors based on your specific needs.'/>
            <HowitWorksComponent step='Step 3. Hire the right tutor' text = "When you're ready, start taking tutoring."/>
            <ShareComponent />
        </div>
    )
}

export default HowItWorks;