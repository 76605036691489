import React, { useEffect, useState, useContext } from "react";
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import Navbar from "../../components/NavbarDesktop";
import NavbarMobile from "../../components/NavbarMobile"
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import API from '../../utils/api'
import { LinearProgress } from "@mui/material";
import SearchListItem from "../../components/SearchListItem";
import SearchListItemMobile from "../../components/SearchListItemMobile";
import { ThemeContext } from "../../App";


function Viewall() {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    const auth = localStorage.getItem('jwt');
    const [progress, setprogress] = useState(true)
    const [data, setdata] = useState([])
    const context = useContext(ThemeContext)

    useEffect(() => {
        setprogress(false)
        API.get('getAllServiceCatalogues')
            .then((res) => {
                console.log("response from view all", res.data)
                setprogress(true)
                setdata(res.data)
            }).catch((err) => {
                console.log("Status:", err.response.status);
                console.log("Error message:", err.message);
                console.log("Error data:", err.response.data);
            })
    }, [])

    return (
        <div style={{ minHeight: '100vh',  backgroundColor: context.appTheme === 'dark'
        ? "#2f3434" // Dark mode color
        : "#effafa"}}>
            {auth ?
                (desktop ? <NavBarAuth /> : <NavbarMobileAuth />) :
                (desktop ? <Navbar /> : <NavbarMobile />)
            }
            {progress && data.length > 0 ? data.map((servicedata) => {
                return (
                    desktop ? <SearchListItem searchResult={servicedata} /> : <SearchListItemMobile searchResult={servicedata} />
                )
            }) : <LinearProgress />}

        </div>
    )
}

export default Viewall;