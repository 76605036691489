import React, {useState} from 'react'
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobile from "../../components/NavbarMobile";
import Navbar from "../../components/NavbarDesktop";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import { Grid, Paper } from "@mui/material";
import API from '../../utils/api'
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from "react-router-dom";

function SettingsComponent ({data}) {
    const navigate = useNavigate();
    const [isEditable, setIsEditable] = useState(false);

    const payload = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        login_id: data.login_id,
        graduation_year: data.graduation_year,
        introduction: data.introduction,
        school: data.school,
    }
    const handleTutor = () => {
        navigate('/editservicecatalogue')
    }
    const handleSave = () => {
        API.put('users', payload)
        .then((response) => {
            console.log("updated user from settings page", response.data)
            API.post('generateToken',response.data)
            .then(tokenresponse => {
                console.log("tokenresponse from signup form descrition updating", tokenresponse.data)
                localStorage.setItem('jwt', tokenresponse.data);
            }) 
            .catch((error) => {
                console.log("Error status:", error.response.status);
                console.log("Error message:", error.message);
                console.log("Error data:", error.response.data);
            })
        })
        .catch(error => {
            console.log("Status:", error.response.status);
            console.log("Error message:", error.message);
            console.log("Error data:", error.response.data);
        })
    }
    const handleClick = () => {
        setIsEditable(true);
    };
    return(
        <div>
            <Box style={{
                margin: "auto",
                marginTop: '25px',
                width: "80%"
            }}>
                <Grid container spacing={3} style={{
                    alignItems: "center",
                    // border: '1px solid black'
                }}>
                    <Grid item xs={4} sx={{
                        textAlign: "center",
                        height: '90vh'
                    }}>
                        <Paper elevation={4} sx={{
                            height: '85vh',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }} >
                            <br/><Avatar alt="Remy Sharp" src="" sx={{ width: 125, height: 125 }} /><br/>
                            <Typography variant="h5">{data.first_name} {data.last_name}</Typography>
                            <Typography variant="h6">{data.email}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={8} sx={{
                        textAlign: "center",
                        height: '90vh'
                    }}>
                        <Paper elevation={4} sx={{
                            height: '85vh'
                        }}>
                            <br/><Typography variant="h4">User Info:</Typography>
                            <TextField sx={{
                                width: '80%',
                                marginTop: '5px'
                            }} disabled={!isEditable} value={data.first_name}  variant="standard" />
                            <TextField sx={{
                                width: '80%',
                                marginTop: '5px'
                            }} disabled={!isEditable} value={data.last_name}  variant="standard" />
                            <TextField sx={{
                                width: '80%',
                                marginTop: '5px'
                            }} disabled={!isEditable} value={data.email} variant="standard" />
                            <TextField sx={{
                                width: '80%',
                                marginTop: '5px'
                            }} disabled={!isEditable} value={data.login_id}  variant="standard" /><br/>d
                            <Button onClick={()=> {navigate('/changepassword')}}>ChangePassword</Button>
                            <TextField sx={{
                                width: '80%',
                                marginTop: '5px'
                            }} disabled={!isEditable} value={data.graduation_year} variant="standard" />
                            <TextField sx={{
                                width: '80%',
                                marginTop: '5px'
                            }} disabled={!isEditable} multiline rows={4} value={data.introduction}  variant="outlined" />
                            <TextField sx={{
                                width: '80%',
                                marginTop: '5px'
                            }} disabled={!isEditable} value={data.school}  variant="standard" />
                            <br /><br />
                            <Button onClick={handleClick} variant="contained" sx={{ width: "80%" }} fullWidth startIcon={<EditIcon />}>
                                Edit
                            </Button> <br /><br/>
                            <Button onClick={handleSave} variant="outlined" sx={{ width: "80%" }} fullWidth >
                                Save changes
                            </Button> <br /><br/>
                            <Button sx={{ width: "80%" }} variant="outlined" onClick={handleTutor}>Edit Tutoring Info</Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default SettingsComponent;