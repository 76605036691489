import React, { useState, useContext } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import { ThemeContext } from "../../App";
import { useNavigate } from 'react-router-dom';
import Avatar from "@mui/material/Avatar";

function NoAuthSearchListItem({ searchResult }) {
    const context  = useContext(ThemeContext)

    const styles = {
        link: {
            color: context.appTheme  === 'dark' ? '#94c2c1' : '#454a4a',
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    };

    const [moreInfo, showMoreInfo] = useState(false);
    const navigate = useNavigate();

    const showFullDescription = (description) => {
        showMoreInfo(true);
    };


    const fadeDownStyle = {
        backgroundImage: context.appTheme === 'light' ? 'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0 , 0))' : 'linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255 , 0))',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        animation: 'fadeDown 2s ease-in-out forwards',
    };

    return (
        <div>
            <Grid container spacing={1} style={{ maxWidth: "73%", margin: "auto" }}>
                <Grid item xs={12}>
                    <Paper elevation={1} style={{ padding: "20px", backgroundColor: context.appTheme  === 'dark' ? '#454a4a' : 'white', boxShadow: context.appTheme === 'light' ? "10px 4px 18px rgb(213 235 234)":"10px 4px 18px #454a4a"}}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <div style={{ display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "6%", width: "80%" }}>
                                    <Avatar style={{ width: "100px", height: "100px" }} src={searchResult.profile_picture_url} />
                                </div>
                            </Grid>

                            <Grid item xs={8}>
                                <Typography variant="subtitle1" color="primary" style={{color: context.appTheme === 'light' ? 'black': 'white'}}>
                                    {searchResult.school}
                                </Typography>
                                <Typography variant="h6" fontWeight="bold" style={{color: context.appTheme === 'light' ? 'black': 'white'}}>
                                    {searchResult.first_name} {searchResult.last_name}
                                </Typography>

                                <div style={{ display: "inline-block", marginBottom: "5px" , color: context.appTheme === 'light' ? 'black': 'white'}}>
                                    <Typography color="textSecondary" style={{color: context.appTheme === 'light' ? 'black': 'white'}}>
                                        Class of {searchResult.graduation_year}
                                    </Typography>
                                    <Typography color="textSecondary" style={{color: context.appTheme === 'light' ? 'black': 'white'}}>
                                        Tutoring Method: {searchResult.tutoring_method}
                                    </Typography>
                                    <Typography
                                        onClick={() => navigate('/signin')}
                                        style={styles.link}
                                    >
                                        Log in to see contact information
                                    </Typography>
                                </div>

                                <Divider />

                                <Typography variant="body2" sx={{ color: "rgb(100 186 186)", marginY: "7px" }}>
                                    Pricing: ${searchResult.tutoring_rate}/hr
                                </Typography>
                            </Grid>

                            <Grid item xs>
                                <Typography>
                                    {!moreInfo && searchResult.introduction.length > 240
                                        ? <Typography style={fadeDownStyle}>{searchResult.introduction.substring(0, 240)}</Typography>
                                        : <Typography style={{color: context.appTheme === 'light' ? 'black': 'white'}}>
                                            {searchResult.introduction}
                                        </Typography>}
                                </Typography>
                                {!moreInfo && searchResult.introduction.length > 240 ? (
                                    <Typography
                                        component="span"
                                        sx={{ color: "blue", cursor: "pointer" }}
                                        onClick={() => showFullDescription(searchResult.introduction)}
                                    >
                                        
                                        See More Info
                                    </Typography>
                                ) : null}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default NoAuthSearchListItem;
