import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/NavbarDesktop";
import { Paper } from "@mui/material";
import NavbarMobile from "../../components/NavbarMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import bgimg from "../../images/bgimg.jpg";
import { fontWeight, textAlign } from "@mui/system";
import ContactForm from "../../components/ContactForm";
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import { useContext } from "react";
import { UserContext } from "../../utils/UserContext";
import LinearProgress from '@mui/material/LinearProgress';
import { useState, useEffect } from "react";
import API from "../../utils/api"
import testimage from '../../images/testimage.webp'
import image from '../../images/SAT.png'


function AboutPage() {
  const theme = useTheme();
  let navigate = useNavigate();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const auth = localStorage.getItem('jwt');
  //const [user, setUser] = useContext(UserContext);
  const [loading, setloading] = useState(true)
  const [userdata, setuserdata] = useState(null)

  useEffect(() => {
    
    API.post('decodetoken',{token: auth})
    .then(response => {
      console.log("response from about page",response.data)
      setuserdata(response.data)
      setloading(false)
    })
    .catch(error => {
      console.log("Status:", error.response.status);
      console.log("Error message:", error.message);
      console.log("Error data:", error.response.data);
    })
  },[])

  const openHomePage = () => {
    navigate("/");
  };
  
  return (
    <div>
    {auth ?
          (desktop ? <NavBarAuth/> : <NavbarMobileAuth/>) :
          (desktop ? <Navbar/> : <NavbarMobile/>)
    }

    <div style={{
      backgroundImage: `url(${bgimg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: "cover",
      width: "100%",
      height: "100%",
    }}>
      <div style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        //backgroundColor: "#3d96fb"
        
      }}>
        
    
      <div style={{
      //transform: 'translateY(1%)'
      }}>
      <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="rgb(61 150 251)" fill-opacity="1" d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
      </div>
      <div style={{
        textAlign: "left",
        backgroundColor: "rgb(61 150 251)",
          transform: 'translateY(-2.0%)',
        minHeight: "300px",
        paddingBottom: "70px"
      
      }}>
      <div style={{ textAlign: "center" }}><Typography variant="h3" color={"white"}>About Us</Typography></div>
      <Grid container>
        <Grid item xs={6} sx={{
          textAlign: "center"
        }}>
          <img src={image} style={{
                      width: desktop ? "200px" : "100px",
                      height: desktop ? "200px" : "100px",
                    }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" color={"white"} sx={{
            
          }}>
          Sanjay Marathe <br/>
          Student at Westview high School, San Diego, CA <br/>
          Developer
          </Typography>
        
        </Grid>
      </Grid>
      <Typography color={"white"} marginTop="5px" marginLeft="10px" marginRight= "10px">
      This web app was born out of personal frustration. As a student, I found myself struggling to find a reliable and convenient platform to find a student tutor for Spanish in the summer of 8th grade. I scoured the internet and asked my friends for recommendations, but I couldn't find a singular platform that met all of my needs. Finally my Mom found a Westview senior on Nextdoor.com who offered to tutor Spanish.That's when I realized that there must be many other students like me who were struggling to find tutors for their subjects. And thus, the idea for our web app was born. <br/> <br/>

My technology stack includes React, Node.js and mySQL. Over the summer of 2022, I started with HTML, CSS and Javascript, and then for the last 8 months, I learnt React, Node.js and SQL.<br/><br/>

This web app connects curated student tutors and students in a seamless and easy-to-use platform. We wanted to create a space where students could find tutors who had previously taken their classes at Westview High School and could provide relevant and effective guidance. This student tutor onboarding procedure includes contacting them by email to ensure that tutors are indeed students at Westview.<br/><br/>

What sets our web app apart is our focus on community. We believe that students are the best resources for other students. That's why we have created a platform where students in Westview who have previously taken classes, can become tutors for students who are currently taking said classes. This creates a unique and supportive community where students can learn from each other and build connections that will last beyond their time in school.<br/><br/>

My goal is to make learning accessible and enjoyable for everyone. We believe that education should be a collaborative and fulfilling experience, and our web app is designed to facilitate that experience. Whether you are struggling with a particular topic or just need some extra support, our tutors are here to help.<br/><br/>
Mentors: Siva Shanmugam, Binu Johnson



      </Typography> 
      
      </div>
      </div>
      </div>
    </div>
    <div style={{
      backgroundColor: "white",
      maxHeight: "400px"
    }}>
    
    <div style={{
      
    }}>
    <ContactForm/>

    {/* {Array.isArray(userdata) ? (
      loading? (<LinearProgress />): (<Typography>{userdata[0].id}{userdata[0].introduction}</Typography>)
    ) : (
      loading? (<LinearProgress />): (<Typography>{userdata.id}{userdata.introduction}</Typography>)
    )} */}
    
    
    {/* <button onClick={() => {setUser('wats up')}}>click here</button> */}
    
    </div>
    
    </div>
      
        
      
    </div>
  );
}



export default AboutPage;
