import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Navbar from "../../components/NavbarDesktop";
import useMediaQuery from "@mui/material/useMediaQuery";
import NavbarMobile from "../../components/NavbarMobile";
import { useTheme } from "@mui/material/styles";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import RegisterComponent from "../../components/RegisterComponent";
import SigninComponent from "../../components/LogInComponent";
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import RegisterForm from "../../components/RefactoredRegisterComponent"

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
    }
    
    TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    };
    
    function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
    }

function RegisterPage() {

    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    const auth = localStorage.getItem('jwt');
    {auth ?
        (desktop ? <NavBarAuth/> : <NavbarMobileAuth/>) :
        (desktop ? <Navbar/> : <NavbarMobile/>)
    }
    return (
        <div>
        {desktop ? <Navbar /> : <NavbarMobile />}
        <RegisterForm/>
        
        </div>
    )
}

export default RegisterPage;