import React, {useState} from "react";
import { Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import testimage from '../../images/testimage.webp'

function UserDisplayComponent ({userinfo})  {
    const [moreInfo, showMoreInfo] = useState(false);
    const showFullDescription = (description) => {
        showMoreInfo(true);
    };
    return(
        <div>
    <Box
        sx={{
        width: "73%",
        margin: "auto",
        boxShadow: "10px 4px 18px rgb(213 235 234)",
        }}
    >
        <Grid
        rowSpacing={1}
        columnSpacing={{ xs: 3, sm: 2, md: 3 }}
        style={{ marginTop: "10px" }}
        >
        <Paper variant="outlined">
            <Grid container>
            {/*First grid*/}

            <Grid item>
                <div
                style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "6%",
                    width: "80%",
                }}
                >
                
                <Avatar style={{width: "100px", height: "100px"}} src={userinfo.profile_picture_url}/>

                </div>
            </Grid>

            {/*Second grid*/}
            <Grid item style={{ marginLeft: "30px" }}>
                <Typography
                fontSize={14}
                color="rgb(92 164 164)"
                sx={{
                    fontWeight: "bold",
                }}
                >
                {userinfo.school}
                </Typography>
                <Typography
                fontSize={18}
                sx={{
                    fontWeight: "bold",
                }}
                >
                {userinfo.first_name} {userinfo.last_name}
                </Typography>

                <div
                style={{
                    display: "inline-block",
                    marginBottom: "5px",
                }}
                >
                <Typography
                    style={{
                    // textAlign: "center",
                    }}
                    color={"rgb(122 142 141)"}
                >
                    Class of {userinfo.graduation_year}
                </Typography>
                <div
                    style={{
                    // textAlign: "center",
                    }}
                >
                    {/* <span>&nbsp;•&nbsp;</span> */}
                </div>

                <Typography
                    style={{
                    // textAlign: "center",
                    }}
                    color={"rgb(122 142 141)"}
                >
                    Tutoring Method: {userinfo.tutoring_method}
                </Typography>
                <div
                    style={{
                    // textAlign: "center",
                    }}
                >
                    {/* <span>&nbsp;•&nbsp;</span> */}
                </div>
                <Typography
                    style={{
                    // textAlign: "center",
                    }}
                    color={"rgb(122 142 141)"}
                >
                    {userinfo.email}
                </Typography>
                </div>
                <div
                style={{
                    marginBottom: "5px",
                }}
                >
                <Divider variant="middle" />
                </div>

                <Typography
                color={"rgb(122 142 141)"}
                sx={{
                    fontWeight: "bold",
                    color: "rgb(100 186 186)",
                    marginBottom: "7px",
                }}
                >
                Username: {userinfo.login_id}
                </Typography>
            </Grid>

            {/*Third grid*/}

            <Grid item xs style={{ marginLeft: "25px", marginRight: "25px" }}>
                <Typography>
                {userinfo.introduction}
                </Typography>
            </Grid>
            </Grid>
        </Paper>
        </Grid>
    </Box>
    </div>
    )
}

export default UserDisplayComponent