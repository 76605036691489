import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import API from '../../utils/api'
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import NavbarMobile from "../../components/NavbarMobile";
import Navbar from "../../components/NavbarDesktop";
import { LinearProgress } from "@mui/material";
import DashboardComponent from "../../components/DashboardComponent";


export default function Dashboard() {
    const { id } = useParams();
    const token = localStorage.getItem('jwt')
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    const auth = localStorage.getItem('jwt');
    const [userdata, setuserdata] = useState(null)
    const [loading, setloading] = useState(true)

    useEffect(() => {
        API.post('decodetoken', { token: token })
            .then(response => {
                setuserdata(response.data)
                console.log('response from settigs page decodetoken', response.data)
                
            }).then(() => {setloading(false)})
            .catch(error => {
                console.log("Status:", error.response.status);
                console.log("Error message:", error.message);
                console.log("Error data:", error.response.data);
            })
    }, [])

    return (
        <div>
            {auth ?
                (desktop ? <NavBarAuth /> : <NavbarMobileAuth />) :
                (desktop ? <Navbar /> : <NavbarMobile />)
            }
            {loading ?  <LinearProgress /> : <DashboardComponent userId={id} userData={userdata}/>}
        </div>


    )
}