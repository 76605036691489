import react from 'react';
import NavBarAuth from '../../components/NavBarAuth';
import NavbarMobileAuth from '../../components/NavBarMobileAuth';
import Navbar from '../../components/NavbarDesktop';
import NavbarMobile from "../../components/NavbarMobile";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function PrivacyPage() {
    const theme = useTheme();
    const auth = localStorage.getItem('jwt');
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    return(
        <div>
            {auth ?
                (desktop ? <NavBarAuth/> : <NavbarMobileAuth/>) :
                (desktop ? <Navbar/> : <NavbarMobile/>)
            }
            Privacy Policy 
Types of Information

“Personal information” is any information that we could use to identify an individual. It does not include personal information that is encoded or anonymized, or publicly available information that has not been combined with non-public information.

How do we use the personal information?

To allow you to register for our web sites, other services and administer, process those registrations
To communicate with you about our products, services and related issues
To evaluate the quality of our products and services, to enhance your experience
To maintain and administer our platform, comply with our legal or internal obligations and policies

We do not use personal information –

To spam
To monetize
How Do We Collect Information?

We collect information two ways: Information we get from your use of the platform & services and information you provide to us directly.

Information we get from your use of the platform & services is primarily non-personally- identifying information of the sort that web browsers, servers and services like Google Analytics typically make available, such as the browser type, language preference, referring site and the time of each visit. Other non-identifying information that we might have access to includes how you use the service (e.g., search queries), your approximate location, cookies etc.


When you use the platform & services, we also collect potentially personally identifying information in the form of Internet Protocol (IP) addresses. But we don’t use that information to identify you and disclose these addresses in the same way we handle other potentially personally identifying information as described below.


Information you provide to us directly. Certain visitors to the platform choose to interact with it in ways that may require them to provide us with personally identifying information. The amount and type of information that is provided depends on the nature of the interaction. For example, we ask visitors who sign up for platform to provide a username and email address. We do not disclose personally identifying information other than as described below –
When a user sign up as Tutor, the tutor search results show the personally identifying information to signed in user.
Change Notification
Although most changes are likely to be minor, we may occasionally need to change this policy. If we do update it, we’ll notify you either by posting the new policy on the website or by emailing you the changes or a link to the modified document. 


Date last revised: April 28, 2023
        </div>
    );
}

export default PrivacyPage;