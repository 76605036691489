import React, {useEffect, useState} from "react";
import AdminHubComponent from "../../components/AdminHubComponent";
import Navbar from "../../components/NavbarDesktop";
import NavbarMobile from "../../components/NavbarMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import MakeAdminComponent from "../../components/MakeAdminComponent";
import UserDisplayComponent from "../../components/UserDisplayComponent";
import API from "../../utils/api"
import { LinearProgress } from "@mui/material/";

function AdminHub () {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    const auth = localStorage.getItem('jwt');
    const [userinfo, setuserinfo] = useState([])
    useEffect(() => {
        API.get('users').then(response => {setuserinfo(response.data)})
    },[])

    return(
        <div>
            {auth ?
                (desktop ? <NavBarAuth/> : <NavbarMobileAuth/>) :
                (desktop ? <Navbar/> : <NavbarMobile/>)
            }
            <AdminHubComponent />
            <MakeAdminComponent />
            <h1>List of users:</h1>

            {userinfo && userinfo.length > 0 ? userinfo.map((result) => {
                return(
                    <UserDisplayComponent userinfo={result} />
                ) 
            }):<LinearProgress />}

        </div>
    )
}
export default AdminHub;