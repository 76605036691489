import { makeStyles } from "@mui/styles";

const useNavBarStyles = makeStyles({
    homePageGrid: {
        background: "white",
        display: "flex",
        alignItems: "center",
    },
    homePageButton: {
        cursor: "pointer",
    },
});

export default useNavBarStyles;