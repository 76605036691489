import React, { useContext } from "react";
import { ThemeContext } from "../../App";
import { Switch, Typography } from "@material-ui/core";
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

export default function ToggleTheme() {

    const context = useContext(ThemeContext)
    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            }}>
                
                {context.appTheme === 'dark' ? <DarkModeIcon style={{ fontSize: '30px', color: 'white' }} /> : <LightModeIcon style={{ fontSize: '30px' }} />}
                <Switch color="primary" onChange={context.toggleTheme} />

                

            </div>
        </div>
    )
}