import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import API from '../utils/api'
import { LinearProgress } from "@mui/material";

const ProtectedAdminRoute = () => {
    // const { setAuthToken, setIsAuthenticated, authToken, isAuthenticated } = useAuth();
    // console.log("isAuthenticated 2:", isAuthenticated);
    // console.log("authToken 2:", authToken);
    const auth = localStorage.getItem('jwt');
    const [loading, setloading] = useState(true);
    const [user, setuser] = useState([])
    useEffect(() => {
        API.post('decodetoken', { token: auth })
            .then(response => {
                console.log("response from protected admin route", response.data)
                setuser(response.data)
                
            }).then(() => {
                setloading(false)
            })
            .catch(error => {
                console.log("Status:", error.response.status)
                console.log("Error message:", error.message);
                console.log("Error data:", error.response.data);
            })
    }, [])
    return (
        loading ? (
            <LinearProgress />
        ) : (
            Array.isArray(user) ? (
                user[0].account_type === 'admin' ? <Outlet /> : <Navigate to='/noauth' />
            ) : (
                user.account_type === 'admin' ? <Outlet /> : <Navigate to='/noauth' />
            )
        )

        
    )

};

export default ProtectedAdminRoute;