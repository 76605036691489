import React from 'react';
import LoginComponent from '../../components/LogInComponent';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import NavbarMobile from "../../components/NavbarMobile";
import Navbar from "../../components/NavbarDesktop";
import NavBarAuth from '../../components/NavBarAuth';
import NavbarMobileAuth from '../../components/NavBarMobileAuth';



function LoginPage () {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    const auth = localStorage.getItem('jwt');

    return(
        <div>
            {auth ?
                (desktop ? <NavBarAuth/> : <NavbarMobileAuth/>) :
                (desktop ? <Navbar/> : <NavbarMobile/>)
            }
            <LoginComponent/>

        </div>
    )
}

export default LoginPage;
