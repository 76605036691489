import React from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import Navbar from "../../components/NavbarDesktop";
import NavbarMobile from "../../components/NavbarMobile";

function UnauthorizedPage () {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    const auth = localStorage.getItem('jwt');
    return(
        <div>
            {auth ?
                (desktop ? <NavBarAuth/> : <NavbarMobileAuth/>) :
                (desktop ? <Navbar/> : <NavbarMobile/>)
            }
            You are unauthorized to access this page. Contact the administrator to gain acess
            <br/><br/><div style={{color: "blue"}}>itsmesmarathe@gmail.com</div>
        </div>
    )
}

export default UnauthorizedPage