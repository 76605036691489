import React, {useContext} from "react";
import Box from "@mui/material/Box";
import { ThemeContext } from "../../App";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import { Paper } from "@mui/material";
import useNavBarStyles from "./styles";
import { LOGO, ABOUT_US, BECOME_A_TUTOR, LOGIN, REGISTER, SIGNUP, HOW, FAQ } from './constants';
import ShareComponent from "../ShareComponent";
import ToggleTheme from "../ToggleTheme";



function Navbar() {
  let navigate = useNavigate();
  let location = useLocation();
  let NavBarClass = useNavBarStyles();
  const context = useContext(ThemeContext)

  const handleMenuSelection = (name) => {
    switch (name) {
      case LOGO:
        location.pathname != "/" && navigate("/", { replace: true });
        return;
      case ABOUT_US:
        location.pathname != "/about" && navigate("/about");
        return;
      case BECOME_A_TUTOR:
        location.pathname != "/signup" && navigate("/signup");
        return;
      case REGISTER:
        location.pathname != "/signin" && navigate("/signin");
        return;
      case SIGNUP:
        location.pathname != "/register" && navigate('/register')
        return;
      case HOW:
        location.pathname != "/howitworks" && navigate('/howitworks') // TODO: change to how it works page
        return;
      case FAQ:
        location.pathname != "/faq" && navigate('/faq')
        return;
      default:
        navigate("/", { replace: true });
        return;
    }
  };

  return (

    <Paper elevation={8}>
      <Grid container className={ context.appTheme === 'dark' ? NavBarClass.containerDark : NavBarClass.container}>
        <Grid className={ context.appTheme === 'dark' ? NavBarClass.homePageGridDark : NavBarClass.homePageGrid} item xs={1.3}>
          {context.appTheme === 'light' ? <Box
            onClick={() => {
              handleMenuSelection(LOGO);
            }}
            className={NavBarClass.homePageButton}
            component="img"
            sx={{
              height: 59,
              width: 180,
              maxHeight: { xs: 30, md: 167 },
              maxWidth: { xs: 90, md: 250 },
            }}
            src={require("../../images/product_logo.png")}
          /> : <Box
          onClick={() => {
            handleMenuSelection(LOGO);
          }}
          className={NavBarClass.homePageButton}
          component="img"
          sx={{
            height: 59,
            width: 180,
            maxHeight: { xs: 30, md: 167 },
            maxWidth: { xs: 90, md: 250 },
          }}
          src={require("../../images/logoDark.png")}
        />}
          
        </Grid>
        <Grid className={context.appTheme === 'dark' ? NavBarClass.SwitchGridDark : NavBarClass.SwitchGrid} item xs={1.3}>
          <ToggleTheme />
        </Grid>

        <Grid className={context.appTheme === 'dark' ? NavBarClass.shareGridDark : NavBarClass.shareGrid} item xs={1.3}>
          <Grid container direction="column" justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Typography variant="h6" style={{color: context.appTheme === 'light' ? '#454a4a' : 'white'}}>Share with</Typography>
            </Grid>
            <Grid item>
              <ShareComponent />
            </Grid>
          </Grid>
        </Grid>



        <Grid className={context.appTheme === 'dark' ? NavBarClass.loginGridDark : NavBarClass.loginGrid} item xs={1.3}>
          {" "}
          <Typography onClick={() => {
            handleMenuSelection(FAQ);
          }}
            className={NavBarClass.loginText} style={{color: context.appTheme === 'light' ? '#454a4a' : 'white'}} >FAQ</Typography>
        </Grid>

        <Grid className={context.appTheme === 'dark' ? NavBarClass.loginGridDark : NavBarClass.loginGrid} item xs={1.3}>
          {" "}
          <Typography onClick={() => {
            handleMenuSelection(HOW);
          }}
            className={NavBarClass.tutorText} style={{color: context.appTheme === 'light' ? '#454a4a' : 'white'}}>How it Works</Typography>
        </Grid>

        <Grid className={context.appTheme === 'dark' ? NavBarClass.loginGridDark : NavBarClass.loginGrid} item xs={1.3}>
          <Typography
            onClick={() => {
              handleMenuSelection(BECOME_A_TUTOR);
            }}
            //style={{ fontSize: "22px", cursor: "pointer" }} Internal styles
            className={NavBarClass.tutorText} //External styles
            style={{color: context.appTheme === 'light' ? '#454a4a' : 'white'}}
          >
            Start Tutoring
          </Typography>
        </Grid>

        <Grid className={context.appTheme === 'dark' ? NavBarClass.aboutUsGridDark : NavBarClass.aboutUsGrid} item xs={1.3}>
          <Typography
            onClick={() => {
              handleMenuSelection(ABOUT_US);
            }}
            //style={{ fontSize: "22px", cursor: "pointer" }} Internal styles
            className={NavBarClass.aboutUsText} //External styles
            style={{color: context.appTheme === 'light' ? '#454a4a' : 'white'}}
          >
            About Us
          </Typography>
        </Grid>
        <Grid className={context.appTheme === 'dark' ? NavBarClass.loginGridDark : NavBarClass.loginGrid} item xs={1.3}>
          {" "}
          <Typography onClick={() => {
            handleMenuSelection(REGISTER);
          }}
            className={NavBarClass.loginText} style={{color: context.appTheme === 'light' ? '#454a4a' : 'white'}}>Sign In</Typography>
        </Grid>
        <Grid className={context.appTheme === 'dark' ? NavBarClass.loginGridDark : NavBarClass.loginGrid} item xs={1.3}>
          {" "}
          <Typography onClick={() => {
            handleMenuSelection(SIGNUP);
          }}
            className={NavBarClass.loginText} style={{color: context.appTheme === 'light' ? '#454a4a' : 'white'}}>Sign Up</Typography>
        </Grid>


      </Grid>
    </Paper>




  );
}
export default Navbar;
