import React, { useState , useEffect} from "react";
import EditServiceCatalogueComponent from "../../components/EditServiceCatalogueComponent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Navbar from "../../components/NavbarDesktop";
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import NavbarMobile from "../../components/NavbarMobile";
import API from "../../utils/api"


function EditServiceCatalogue () {
    const theme = useTheme();
    const token = localStorage.getItem('jwt');
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    const [servicedata, setservicedata] = useState(false)
    useEffect(() => {
        console.log("token is from service page: ", token)
        API.post('decodetoken',{token:token})
        .then(response => {
            console.log("response from editservicecataloge", response.data[0])
            API.get(`servicecatalogue/${response.data[0].id}`)
            .then(response => {
                console.log("response from getservice catalog", response.data)
                setservicedata(response.data)
            })
            .catch((error) => {
                console.log("Status:", error.response.status);
                console.log("Error message:", error.message);
                console.log("Error data:", error.response.data);
            })
        })
        .catch((error) => { 
            console.log("Status:", error.response.status);
            console.log("Error message:", error.message);
            console.log("Error data:", error.response.data);
        })
        },[])
        console.log("servicedata from edit page:", servicedata)
    
    return(
        <div>
            {token ?
            (desktop ? <NavBarAuth/> : <NavbarMobileAuth/>) :
            (desktop ? <Navbar/> : <NavbarMobile/>)
            }
            
            {servicedata && servicedata.length > 0 ? servicedata.map((data) => { 
            console.log("data == ", data);
            return(
                <EditServiceCatalogueComponent servicedata={data}/>
            ) 
            }):
            <div style={{
            textAlign: "center"
            }}>You are not tutoring anything</div>
            }

        </div>
    )
}

export default EditServiceCatalogue;
