import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./useAuth";
import API from "./api"
import React, { useState, useEffect } from "react";

const ProtectedAuthRoute = () => {
    // const { setAuthToken, setIsAuthenticated, authToken, isAuthenticated } = useAuth();
    // console.log("isAuthenticated 2:", isAuthenticated);
    // console.log("authToken 2:", authToken);
    const auth = localStorage.getItem('jwt');
    


    return (
        // auth.token ? <Outlet/> : <Navigate to = '/' />
    
        auth ? <Navigate to = '/' /> : <Outlet/>
    )
};

export default ProtectedAuthRoute;