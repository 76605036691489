import React, { useState, useEffect, useContext } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Paper, Collapse } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SearchBar from "../../components/SearchBar";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AlertTitle from '@mui/material/AlertTitle';
import { ThemeContext } from "../../App";
import MuiAlert from '@mui/material/Alert';
import API from "../../utils/api"



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function RefactoredSignUpForm({ data }) {
    const navigate = useNavigate();

    // ui
    const context = useContext(ThemeContext)
    const paperStyle = { padding: 20, height: '80vh', width: '400px', margin: '20px auto', backgroundColor: context.appTheme === 'dark' ? '#454a4a' : 'white' }
    const avatarStyle = { backgroundColor: 'rgb(122 142 141)' }

    //ui state
    const [open, setOpen] = React.useState(false)
    const [erroropen, seterroropen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [buttonloading, setbuttonloading] = useState(false)

    // data state
    const [subjects, SetSubjects] = useState(false);
    const [users, SetUsers] = useState(null);
    const [tutoring_rate, Settutoring_rate] = useState();
    const [user_id, Setuser_id] = useState();
    const [introduction, Setintroduction] = useState();
    const [tutoring_method, Settutoring_method] = useState();
    const [subjectname, setsubjectname] = useState();
    const [subject_id, Setsubject_id] = useState();







    const handleClick = () => {
        setOpen(true);
    };
    const handleErrorClick = () => {
        seterroropen(true)
    }
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        seterroropen(false)
    };
    const handleSubject = (event, value) => {
        console.log("event == ", event);
        console.log("value == ", value);
        console.log("Subject id is:", value.id);
        Setsubject_id(value.id)
        setsubjectname(value.name)
    }
    const handleHome = () => {
        navigate("/");
    }
    const handleEdit = () => {
        navigate("/editservicecatalogue")
    }
    const handleBecomeTutor = () => {
        console.log("become tutor called")
        API.put(`users/${Array.isArray(data) ? data[0].id : data.id}`, { introduction: introduction })
            .then(response => {
                console.log("this is the updated user:", response.data)
                console.log("user id:", user_id)
                API.post('generateToken', response.data)
                    .then(tokenresponse => {
                        console.log("tokenresponse from signup form descrition updating", tokenresponse.data)
                        localStorage.setItem('jwt', tokenresponse.data);
                        setbuttonloading(false)
                    })
                    .catch((error) => {
                        console.log("Error status:", error.response.status);
                        console.log("Error message:", error.message);
                        console.log("Error data:", error.response.data);
                    })
            })
            .catch((error) => {
                console.log("the error is from updating the introduction payload")
                console.log("Status:", error.response.status)
                console.log("Error message:", error.message);
                console.log("Error data:", error.response.data);
            })
        const payload = {
            tutoring_rate: tutoring_rate,
            user_id: Array.isArray(data) ? data[0].id : data.id,
            subject_id: subject_id,
            tutoring_method: tutoring_method
        }

        API.post('createServiceCatalogue', payload)
            .then((response) => {
                console.log("createservicecatalog resposnse", response.data);
                handleClick();
                setbuttonloading(false);
            })
            .catch((error) => {
                console.log("Error status:", error.response.status);
                console.log("Error message:", error.message);
                console.log("Error data:", error.response.data);
                console.log(payload);
                handleErrorClick();
            })
    }

    useEffect(() => {
        API.get(`subjects`)
            .then(response => {
                console.log("response == ", response.data);
                SetSubjects(response.data);

            }).catch((error) => {
                console.log("Error status:", error.response.status);
                console.log("Error message:", error.message);
                console.log("Error data:", error.response.data);
            })
        API.get(`users`)
            .then(userresponse => {
                console.log("userresponse == ", userresponse.data);
                SetUsers(userresponse.data);
            }).catch((error) => {
                console.log("Error status:", error.response.status);
                console.log("Error message:", error.message);
                console.log("Error data:", error.response.data);
            })

    },[])
    return (
        <div>
            <Grid>
                <Paper elevation={10} style={paperStyle}>
                    <Grid align='center'>
                        <Avatar style={avatarStyle}><RocketLaunchIcon /></Avatar>
                        <h2 style={{ color: context.appTheme === 'dark' ? 'white' : 'black' }}>Become a Tutor</h2>
                        <Collapse in={open}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={handleClose}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                <AlertTitle>Request Sucesfull!</AlertTitle>
                                You will recieve an email notification when your tutoring request has been accepted
                            </Alert>
                        </Collapse>
                        <Collapse in={erroropen}>
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={handleClose}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                <AlertTitle>There has been an error</AlertTitle>
                                Please verify that you are not aleady a tutor and all fields are filled
                            </Alert>
                        </Collapse>
                        <div>
                            <TextField
                                variant="standard"
                                label="Tutoring Rate"
                                name="tutoring_rate"
                                fullWidth
                                InputLabelProps={{
                                    style: { color: context.appTheme === 'dark' ? 'white' : 'black' }
                                }}
                                onChange={(e) => {
                                    console.log("changed");
                                    Settutoring_rate(e.target.value);
                                }}
                            />

                        </div><br />
                        <div>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: context.appTheme === 'dark' ? 'white' : 'black' }}>Tutoring Method</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    style={{ color: context.appTheme === 'dark' ? 'white' : 'black' }}
                                    onChange={
                                        (e) => {
                                            console.log("changed tutoring method")
                                            Settutoring_method(e.target.value)
                                        }
                                    }
                                >
                                    <FormControlLabel value="Online" control={<Radio />} label="Online" />
                                    <FormControlLabel value="In-Person" control={<Radio />} label="In-Person" />
                                    <FormControlLabel value="Both" control={<Radio />} label="Both" />
                                </RadioGroup>
                            </FormControl>
                            <SearchBar labelColor={context.appTheme === 'dark' ? 'white' : 'black'} handleChange={handleSubject} option={subjects} label={"Enter the course that you want to tutor"} fullWidth />
                        </div>
                        <br />
                        <div>
                            <TextField
                                label="Profile description"
                                InputProps={{
                                    style: { color: context.appTheme === 'dark' ? 'white' : 'black' }, // Specify the text color here
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <div style={{ position: "absolute", top: "0", right: "0" }}>
                                                <IconButton onClick={handleMenuClick} style={{ color: "rgb(24 118 210)" }}>
                                                    <HelpOutlineOutlinedIcon />
                                                </IconButton>
                                            </div>
                                        </InputAdornment>
                                    )
                                }}
                                defaultValue={Array.isArray(data) ? data[0].introduction : data.introduction}
                                multiline
                                fullWidth
                                InputLabelProps={{
                                    style: { color: context.appTheme === 'dark' ? 'white' : 'black' } // Specify the label color here
                                }}
                                name="description"
                                rows={4}
                                onChange={(e) => {
                                    console.log("changed")
                                    Setintroduction(e.target.value)
                                }}
                            />

                            {/* {loading? (<LinearProgress />): (<Typography>{JSON.stringify(data.introduction, null, 2)}</Typography>)} */}


                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleMenuClose}>Provide a concise summary of your <br /> qualifications, experience, subjects you specialize in,<br /> and teaching style. Highlight your skills, passion for teaching, <br /> and approach to helping students succeed.</MenuItem>
                            </Menu>
                        </div>
                    </Grid>
                    <br />
                    <Button variant="contained" fullWidth style={{ marginTop: '10px' }} disabled={buttonloading} onClick={handleBecomeTutor}>{buttonloading ? <CircularProgress size={20} /> : "Become a tutor"}</Button>
                    <Button variant="outlined" fullWidth style={{ marginTop: '10px' }} onClick={handleEdit}>Edit this info</Button>

                </Paper>
            </Grid>
        </div>
    )
}