import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./useAuth";

const ProtectedRoute = () => {
    // const { setAuthToken, setIsAuthenticated, authToken, isAuthenticated } = useAuth();
    // console.log("isAuthenticated 2:", isAuthenticated);
    // console.log("authToken 2:", authToken);
    const auth = localStorage.getItem('jwt');
    return (
        // auth.token ? <Outlet/> : <Navigate to = '/' />
    
        auth ? <Outlet/> : <Navigate to = '/signin' />
    )
};

export default ProtectedRoute;