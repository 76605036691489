import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Paper, Grid } from '@mui/material';
import ad from '../../images/ad.jpeg'
import adqr from '../../images/adqr.jpeg'


const useStyles = makeStyles((theme) => ({
    adContainer: {
        //padding: '16px', // Set your desired padding value
        //marginTop: '16px', // Set your desired margin value
        display: 'flex',
        justifyContent: 'space-between',
    },
    ad: {
        width: '45%',
    },
    adImage: {
        width: '35%',
        height: 'auto',
        marginTop: '10px',
        marginLeft: '50px'
    },
}));

const AdComponent = () => {
    const classes = useStyles();

    return (
        // <Container>
        //     <Paper elevation={3} className={classes.adContainer}>
        //         <Grid container spacing={2}>
        //             <Grid item xs={6} className={classes.ad}>
        //                 <img src={ad} alt="Ad 1" className={classes.adImage} />
        //             </Grid>
        //             <Grid item xs={6} className={classes.ad}>
        //                 <img src={adqr} alt="Ad 2" className={classes.adImage} />
        //             </Grid>
        //         </Grid>
        //     </Paper>
        // </Container>
        <div style={{
            marginRight: '50px'
        }}>
            <img src={adqr} alt="Ad 2" className={classes.adImage} />
            <img src={ad} alt="Ad 1" className={classes.adImage} />
        </div>
    );
};

export default AdComponent;
