import { Grid } from "@mui/material";
import React from "react";
import {FacebookShareButton, WhatsappShareButton, EmailShareButton, TwitterShareButton} from "react-share";
import {FacebookIcon, WhatsappIcon, EmailIcon, TwitterIcon} from "react-share";

function ShareComponent(){
    const size = 27;
    return(
        <div>
            <Grid container>
                <Grid item xs={3}>
                    <FacebookShareButton url="https://boredtutors.com" quote="
                        Struggling to find a reliable student tutor for Spanish in 8th grade led to the birth of our web app.  Realizing others faced the same challenge, we created a seamless platform connecting curated student tutors with students.  Our focus on community fosters learning, connections, and enjoyable education for all. " hashtag="#LearningMadeEasy #StudentTutors">
                        <FacebookIcon  size={size} logoFillColor="white" round={true}></FacebookIcon>
                    </FacebookShareButton>
                </Grid>
                <Grid item xs={3}>
                <WhatsappShareButton url="https://boredtutors.com" title="
                        Struggling to find a reliable student tutor for Spanish in 8th grade led to the birth of our web app.  Realizing others faced the same challenge, we created a seamless platform connecting curated student tutors with students.  Our focus on community fosters learning, connections, and enjoyable education for all. #StudentTutors #LearningMadeEasy">
                        <WhatsappIcon  size={size} logoFillColor="white" round={true}></WhatsappIcon>
                </WhatsappShareButton>
                </Grid>
                <Grid item xs={3}>
                <EmailShareButton url="https://boredtutors.com" subject="Discover Our Web App: Connecting Students with Trusted Student Tutors" body={`
                        Hello, 

                        I hope this email finds you well. I wanted to share an exciting development in the world of student tutoring that can greatly benefit your educational journey.
                        
                        This web app is a seamless and user-friendly platform connecting curated student tutors with students like you. What sets this web app apart is the community focus. Students are the best resources for each other, so a space where tutors, who can provide relevant and effective guidance, was created.
                        
                        The goal is to make learning accessible, enjoyable, and collaborative for everyone. Whether you're struggling with a specific topic or need extra support, dedicated tutors are here to assist you.
                        
                        I invite you to visit this web app at boredtutors.com and explore the wide range of tutors available. I'm confident you'll find the perfect match for your learning needs.
                        
                        Best regards, 
                        [YOUR NAME HERE]
                        
                    `}>
                        <EmailIcon size={size} logoFillColor="white" round={true}></EmailIcon>
                    </EmailShareButton>
                </Grid>
                <Grid item xs={3}>
                <TwitterShareButton url="https://boredtutors.com" title="
                        Struggling to find a reliable student tutor for Spanish in 8th grade led to the birth of our web app.  Realizing others faced the same challenge, we created a seamless platform connecting curated student tutors with students.  Our focus on community fosters learning, connections, and enjoyable education for all.  
                    " hashtag="#LearningMadeEasy #StudentTutors">
                        <TwitterIcon  size={size} logoFillColor="white" round={true}></TwitterIcon>
                    </TwitterShareButton>
                </Grid>
                
            </Grid>
        </div>
    )
}
export default ShareComponent;