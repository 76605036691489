import { Grid, ListItemIcon, Paper } from "@mui/material";
import React, {useState, useEffect, useContext} from "react";
import {LOGO,ABOUT_US,BECOME_A_TUTOR,LOGIN, REGISTER, SETTINGS, HOW, FAQ , DASH} from '../NavbarDesktop/constants'
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import useNavBarAuthStyles from "../NavbarDesktop/styles";
import Avatar from '@mui/material/Avatar';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ShareComponent from "../ShareComponent";
import API from '../../utils/api'
import {ThemeContext} from '../../App'
import ToggleTheme from "../ToggleTheme";


function NavBarAuth() {
    let navigate = useNavigate();
    let location = useLocation();
    let NavBarAuthClass = useNavBarAuthStyles();
    const [anchorEl, SetanchorEl] = useState(null)
    const [firstInitial, SetfirstInitial] = useState(null)
    const [lastInitial, SetlastInitial] = useState(null)
    const [token, settoken] = useState('')
    const [userdata, setuserdata] = useState(null)
    const [loading, setloading] = useState(true)
    const jwttoken = localStorage.getItem('jwt')
    const context = useContext(ThemeContext)

    const open = Boolean(anchorEl)

    useEffect(() => {
        API.post('decodetoken',{token: jwttoken})
        .then(response => {
            setuserdata(response.data)
            setloading(false)
            console.log('response from navbar page decodetoken', response.data)
        })
        .catch(error => {
            console.log("Status:", error.response.status);
            console.log("Error message:", error.message);
            console.log("Error data:", error.response.data);
        })
    },[])
    

    useEffect(() => {
        const token = localStorage.getItem('jwt')
        settoken(token)
    })
    const handleClick = (event) => {
        SetanchorEl(event.currentTarget)
    }
    const handleClose = (event) => {
        SetanchorEl(null);
    }
    const handleLogout = (event) => {
        localStorage.removeItem('jwt')
        window.location.href = "./"
    }
    
    
    
    
    const handleMenuSelection = (name) => {
        switch (name) {
            case LOGO:
            location.pathname != "/" && navigate("/", { replace: true });
            return;
            case ABOUT_US:
            location.pathname != "/about" && navigate("/about");
            return;
            case SETTINGS:
            location.pathname != "/settings" && navigate("/settings");
            return;
            case BECOME_A_TUTOR:
            location.pathname != "/signup" && navigate("/signup");
            return;
            case REGISTER:
            location.pathname != "/signin" && navigate("/signin");
            return;
            case FAQ:
            location.pathname != "/faq" && navigate("/faq");
            return;
            case DASH:
            location.pathname != "/dashboard/1" && navigate(`/dashboard/${Array.isArray(userdata) ? userdata[0].id : userdata.id}`);
            return;
            case HOW:
            location.pathname != "/howitworks" && navigate("/howitworks");
            return;
            default:
            navigate("/", { replace: true });
            return;
        }
    };
    
    function stringToColor(string) {
        let hash = 0;
        let i;
    
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
    
        let color = '#';
    
        for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
    
        return color;
    }
    
        function stringAvatar(name) {
            return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            };
        }
    
    

    
    return(
        <Paper elevation='8'>
            <Grid container>
                <Grid style={{
                    background: context.appTheme === 'dark' ? "#282828" : 'white'
                }} item xs={1.3}>
                    <Box
                    onClick={() => {
                        handleMenuSelection(LOGO);
                    }}
                    classname = {NavBarAuthClass.homePageButton}
                    component="img"
                    sx={{
                        height: 59,
                        width: 180,
                        maxHeight: { xs: 30, md: 167 },
                        maxWidth: { xs: 90, md: 250 },
                    }}
                    src={ context.appTheme === 'dark' ? require("../../images/logoDark.png") : require("../../images/product_logo.png")}
                    />
                </Grid>
                <Grid className={context.appTheme === 'dark' ?  NavBarAuthClass.loginGridDark : NavBarAuthClass.loginGrid} item xs={1.3}>
                <Grid container direction="column" justifyContent="flex-end" alignItems="center">

                    <ToggleTheme />

                </Grid>
                </Grid>
                <Grid className={context.appTheme === 'dark' ?  NavBarAuthClass.loginGridDark : NavBarAuthClass.loginGrid} item xs={1.3}>
                <Grid container direction="column" justifyContent="flex-end" alignItems="center">
                    <Grid item>
                    <Typography variant="h6" style={{
                color: context.appTheme === 'dark' ? 'white' : '#454a4a'
            }} >Share with</Typography>
                    </Grid>
                    <Grid item>
                    <ShareComponent />
                    </Grid>
                </Grid>
                </Grid>
                <Grid className={context.appTheme === 'dark' ?  NavBarAuthClass.tutorGridDark : NavBarAuthClass.tutorGrid} item xs={1.3}>
                    <Typography
                        onClick={() => {
                            handleMenuSelection(FAQ);
                        }}
            //style={{ fontSize: "22px", cursor: "pointer" }} Internal styles
            className={NavBarAuthClass.tutorText} //External styles
            style={{
                color: context.appTheme === 'dark' ? 'white' : '#454a4a'
            }} 
                >
                    FAQ
                </Typography>
                </Grid>

                <Grid className={context.appTheme === 'dark' ?  NavBarAuthClass.tutorGridDark : NavBarAuthClass.tutorGrid} item xs={1.3}>
                    <Typography
                        onClick={() => {
                            handleMenuSelection(HOW);
                        }}
            //style={{ fontSize: "22px", cursor: "pointer" }} Internal styles
            className={NavBarAuthClass.tutorText} //External styles
            style={{
                color: context.appTheme === 'dark' ? 'white' : '#454a4a'
            }} 
                >
                How it Works    
                </Typography>
                </Grid> 
                <Grid className={ context.appTheme === 'dark' ?  NavBarAuthClass.tutorGridDark : NavBarAuthClass.tutorGrid} item xs={1.3}>
                    <Typography
                        onClick={() => {
                            handleMenuSelection(BECOME_A_TUTOR);
                        }}
            //style={{ fontSize: "22px", cursor: "pointer" }} Internal styles
            className={NavBarAuthClass.tutorText} //External styles
            style={{
                color: context.appTheme === 'dark' ? 'white' : '#454a4a'
            }} 
                >
            Start Tutoring
                </Typography>
                </Grid>

        <Grid className={context.appTheme === 'dark' ?  NavBarAuthClass.aboutUsGridDark : NavBarAuthClass.aboutUsGrid} item xs={1.3}>
                    <Typography
                    onClick={() => {
                    handleMenuSelection(ABOUT_US);
                    }}
            //style={{ fontSize: "22px", cursor: "pointer" }} Internal styles
            className={NavBarAuthClass.aboutUsText} //External styles
            style={{
                color: context.appTheme === 'dark' ? 'white' : '#454a4a'
            }} 
            >
            About Us
            </Typography>
        </Grid>

        <Grid className={context.appTheme === 'dark' ?  NavBarAuthClass.aboutUsGridDark : NavBarAuthClass.aboutUsGrid} item xs={1.6}>
                    <Typography
                    onClick={() => {
                    handleMenuSelection(DASH);
                    }}
            //style={{ fontSize: "22px", cursor: "pointer" }} Internal styles
            className={NavBarAuthClass.tutorText} //External styles
            style={{
                color: context.appTheme === 'dark' ? 'white' : '#454a4a'
            }} 
            >
            Dashboard
            </Typography>
        </Grid>

        {/* <Grid className={NavBarAuthClass.loginGrid} item xs={1}>
            {" "}
            <Typography onClick={() => {
                handleMenuSelection(REGISTER);
            }}
            className={NavBarAuthClass.loginText}>Sign In</Typography>
        </Grid> */}
        <Tooltip title="Account settings">
            <Grid item xs={1.3} sx={{
                display: 'flex',
                justifyContent: 'center',
                background: context.appTheme === 'dark' ? '#282828' : 'white'
            }}>
                <IconButton 
                    size="small"
                    sx={{ ml: 2 }}
                    onClick = {handleClick}
                >
                <Avatar>
                {/* {Array.isArray(userdata) ? userdata[0].first_name[0] : userdata.first_name[0]} */}
                
                </Avatar>
                </IconButton>
            </Grid>
        </Tooltip>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose}>
            <MenuItem onClick={() => {
                        handleMenuSelection(SETTINGS);
            }}><ListItemIcon><SettingsIcon/></ListItemIcon>Settings</MenuItem>

            <MenuItem sx={{
                color: 'red'
            }} onClick={handleLogout}><ListItemIcon sx={{
                color: 'red'
            }}><LogoutIcon/></ListItemIcon>Log out</MenuItem>
        </Menu>
        

            </Grid>
        </Paper>
    )
}

export default NavBarAuth;