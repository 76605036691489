import React from "react";
import { Card, CardContent, Typography  } from "@mui/material";

function FAQComponent (props) { 
    return(
        <div>
        <Card variant="outlined" sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
            <Typography variant="h5" sx={{ color: "rgb(80 128 189)" }}>
            {props.question}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 2 }}>
            {props.answer}
            </Typography>
        </CardContent>
        </Card>
        </div>
    )
}

export default FAQComponent;