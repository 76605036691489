import ChangePasswordComponent from "../../components/ChangePasswordComponent";
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import Navbar from "../../components/NavbarDesktop";
import NavbarMobile from "../../components/NavbarMobile";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React,{useState, useEffect} from "react";
import API from "../../utils/api"


function ChangePassword () {
    const auth = localStorage.getItem('jwt');
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    const [login_id, setlogin_id] = useState();
    const [userdata, setuserdata] = useState(false);
    const [isupdated, setisupdated] = useState({})

    useEffect(() => {
    API.post('decodetoken',{token: auth})
    .then(response => {
        console.log("response from decode token change password(username)", response.data[0].login_id)
        setuserdata(response.data)
    })
    .catch(error => {
        console.log("Status:", error.response.status);
        console.log("Error message:", error.message);
        console.log("Error data:", error.response.data);
    })
    },[])

    
    return(
        <div>
        {auth ?
        (desktop ? <NavBarAuth/> : <NavbarMobileAuth/>) :
        (desktop ? <Navbar/> : <NavbarMobile/>)
        }
        
        
        {userdata ? userdata.map((data) => { 
        console.log("data == ", data);
        return(
            <ChangePasswordComponent token={auth} data={data} /> 
        ) 
        }):
        <div style={{
        textAlign: "center"
        }}>loading...</div>
        } 
        
        
        </div>
    )
}

export default ChangePassword;