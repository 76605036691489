import React, {useEffect, useState} from "react";
import API from '../../utils/api';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Typography } from "@mui/material";



function HandleAccept (id) {
    console.log(id)
    const payload = {
        tutor_status: 'activated'
    }
    API.put(`users/${id}`,payload)
    .then(response => {
        console.log("this is the updated user:", response.data)
        
    })
    .catch((error) => {
        console.log("Status:", error.response.status)
        console.log("Error message:", error.message);
        console.log("Error data:", error.response.data);
    })
    window.location.href = "./admin"
    
}
function AdminHubComponent () {
    const [users, SetUsers] = useState([]);
    const [allusers, setallusers] = useState([])
    const [activatedusers, Setactivatedusers] = useState([]);

    useEffect(()=>{
        API.get('users').then(response => {setallusers(response.data)})
        API.get(`pending`) 
        .then(response => {
        console.log("pending users== ",response.data);
        SetUsers(response.data)
        const activatedUsersArray = Object.values(response.data).filter(user => user.tutor_status === "activated");
        Setactivatedusers(activatedUsersArray)
        console.log("users", users)
        })
    },[]);
    
    const styles = {
        table: {
            borderCollapse: 'collapse',
            width: '95%',
            marginTop: '20px',
            border: '1px solid black', // add border property
            tableLayout: 'fixed',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        th: {
            backgroundColor: '#3295a8',
            border: '1px solid black', // add border property
            padding: '8px',
            textAlign: 'left',
            fontWeight: 'bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordWrap: 'break-word',
        },
        td: {
            border: '1px solid black', // add border property
            padding: '8px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordWrap: 'break-word',
            
        },

        tr: {
            backgroundColor: '#f9f9f9',
        },

    };
    return(
        <div>
        <table style={styles.table}>
        <thead>
            <tr style={styles.tr}>
                <th style={styles.th}>ID</th>
                <th style={styles.th}>Username</th>
                <th style={styles.th}>Password hash</th>
                <th style={styles.th}>first_name</th>
                <th style={styles.th}>last_name</th>
                <th style={styles.th}>graduation_year</th>
                <th style={styles.th}>email</th>
                <th style={styles.th}>profile_picture_url</th>
                <th style={styles.th}>school_district_id</th>
                <th style={styles.th}>introduction</th>
                <th style={styles.th}>tutor_status</th>
                <th style={styles.th}>school_id</th>
                <th style={styles.th}>school</th>
                <th style={styles.th}>tutoring_method</th>
                <th style={styles.th}>Accept Tutor?</th>
            </tr>
        </thead>
        <tbody>
        {users.map(user => (
            <tr key={user.id} style={styles.tr}>
            <td style={styles.td}>{user.id}</td>
            <td style={styles.td}>{user.login_id}</td>
            <td style={styles.td}>{user.password_hash}</td>
            <td style={styles.td}>{user.first_name}</td>
            <td style={styles.td}>{user.last_name}</td>
            <td style={styles.td}>{user.graduation_year}</td>
            <td style={styles.td}>{user.email}</td>
            <td style={styles.td}>{user.profile_picture_url}</td>
            <td style={styles.td}>{user.school_district_id}</td>
            <td style={styles.td}>{user.introduction}</td>
            <td style={styles.td}>{user.tutor_status}</td>
            <td style={styles.td}>{user.school_id}</td>
            <td style={styles.td}>{user.school}</td>
            <td style={styles.td}>{user.tutoring_method}</td>
            <td style={styles.td}>
            {Array.isArray(user) ? (
                <Button onClick={() => HandleAccept(user[0].id)}>Accept</Button>
            ) : <Button onClick={() => HandleAccept(user.id)}>Accept</Button>}

            </td>
            </tr>
        ))}
        </tbody>
    </table>
    <br/>
    <Divider variant="middle" />
    <Typography variant="h4" style={{
        textAlign: "center"
    }}>Accepted Tutors:</Typography>
        <table style={styles.table}>
            <thead>
                <tr style={styles.tr}>
                <th style={styles.th}>ID</th>
                <th style={styles.th}>Username</th>
                <th style={styles.th}>Password hash</th>
                <th style={styles.th}>first_name</th>
                <th style={styles.th}>last_name</th>
                <th style={styles.th}>graduation_year</th>
                <th style={styles.th}>email</th>
                <th style={styles.th}>profile_picture_url</th>
                <th style={styles.th}>school_district_id</th>
                <th style={styles.th}>introduction</th>
                <th style={styles.th}>tutor_status</th>
                <th style={styles.th}>school_id</th>
                <th style={styles.th}>school</th>
                <th style={styles.th}>tutoring_method</th>
                <th style={styles.th}>Accept Tutor?</th>
                </tr>
            </thead>
            <tbody>
                <tr style={styles.tr}>
                {activatedusers.map(activateduser => (
                <tr key={activateduser.id} style={styles.tr}>
                <td style={styles.td}>{activateduser.id}</td>
                <td style={styles.td}>{activateduser.login_id}</td>
                <td style={styles.td}>{activateduser.password_hash}</td>
                <td style={styles.td}>{activateduser.first_name}</td>
                <td style={styles.td}>{activateduser.last_name}</td>
                <td style={styles.td}>{activateduser.graduation_year}</td>
                <td style={styles.td}>{activateduser.email}</td>
                <td style={styles.td}>{activateduser.profile_picture_url}</td>
                <td style={styles.td}>{activateduser.school_district_id}</td>
                <td style={styles.td}>{activateduser.introduction}</td>
                <td style={styles.td}>{activateduser.tutor_status}</td>
                <td style={styles.td}>{activateduser.school_id}</td>
                <td style={styles.td}>{activateduser.school}</td>
                <td style={styles.td}>{activateduser.tutoring_method}</td>
                <td style={styles.td}><Button variant="contained" color="success" fullWidth>
                        Accept
                    </Button>
                </td> 
            </tr>
        ))}
                </tr>
            </tbody>
        </table>
        
    </div>
    
    )
}

export default AdminHubComponent;