import { makeStyles } from "@mui/styles";


const useNavBarAuthStyles = makeStyles({
    aboutUsText: {
        fontSize: "22px !important", 
        cursor: "pointer",
        //color: "rgb(61,150,251)",
        transition: "color .3s ease-in-out, box-shadow .3s ease-in-out",
        '&:hover': {
            /*color: "rgb(61,150,251)",
            textDecoration: "underline"*/
            boxShadow: "inset 100px 0 0 0 #54b3d6",
            color: "white"
        },
    },
    aboutUsGrid: {
        background: 'white',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    aboutUsGridDark: {
        background: '#282828',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    loginGrid: {
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        
    },
    loginGridDark: {
        background: "#282828",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        
    },
    shareGrid: {
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    shareGridDark: {
        background: "#282828",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    loginText: {
        fontSize: "22px !important", 
        cursor: "pointer",
        transition: "color .3s ease-in-out, box-shadow .3s ease-in-out",
        // width: "80%",
        // wordBreak: "break-all",
        // overflowWrap: "break-word",
        '&:hover': {
            /*color: "rgb(61,150,251)",
            textDecoration: "underline"*/
            boxShadow: "inset 100px 0 0 0 #54b3d6",
            color: "white"
        },
    },
    homePageGrid: {
        background: "white", 
        display: "flex", 
        alignItems: "center"
    },
    homePageGridDark: {
        background: "#282828", 
        display: "flex", 
        alignItems: "center"
    },
    homePageButton: {
        cursor: "pointer" 
    },
    tutorGrid: {
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tutorGridDark: {
        background: "#282828",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tutorText: {
        fontSize: "22px !important", 
        cursor: "pointer",
        transition: "color .3s ease-in-out, box-shadow .3s ease-in-out",
        '&:hover': {
            /*color: "rgb(61,150,251)",
            textDecoration: "underline"*/
            boxShadow: "inset 200px 0 0 0 #54b3d6",
            color: "white"
        },
    },
    profileGrid: {
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    profileGridDark: {
        background: "#282828",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    profileIcon: {
        color: 'rgb(61 150 251)',
        backgroundColor: 'white',
        borderRadius: "0%",
        // marginLeft: 'auto',
        // marginRight: 'auto',
        // marginTop: '10px',
        // marginBottom: '1px',
        // width: '50px',
        // height: '50px',
        // border: '2px solid rgb(61 150 251)'
    },
    container: {
        backgroundColor: 'white',
    },
    containerDark: {
        backgroundColor: '#282828',
    },
    SwitchGridDark: {
        background: "#282828",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    SwitchGrid: {
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
    
});

export default useNavBarAuthStyles;