import React, { useState, useContext, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import testimage from '../../images/testimage.webp'
import { ThemeContext } from "../../App";



export const generateRandomColor = (firstName) => {
  const firstLetter = firstName.charAt(0).toLowerCase();
  const letterCode = firstLetter.charCodeAt(0) - 97; // ASCII code for 'a' is 97

  const r = (letterCode * 7) % 256;
  const g = (letterCode * 13) % 256;
  const b = (letterCode * 19) % 256;

  return `rgb(${r}, ${g}, ${b})`;
};

function generateColorFromInitial(initial) {
  // Define an array of possible colors
  const colors = ['#ff5733', '#33ff57', '#5733ff', '#ff33d1', '#33a5ff', '#ffc933'];

  // Use the ASCII value of the initial character to pick a color from the array
  const charCode = initial.charCodeAt(0);
  const colorIndex = charCode % colors.length;

  // Return the selected color
  return colors[colorIndex];
}

function SearchListItem({ searchResult }) {
  const [moreInfo, showMoreInfo] = useState(false);
  const showFullDescription = (description) => {
    showMoreInfo(true);
  };
  const context = useContext(ThemeContext)
    
  const fadeDownStyle = {
    backgroundImage: context.appTheme === 'light' ? 'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0 , 0))' : 'linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255 , 0))',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: 'fadeDown 2s ease-in-out forwards',
    
  };

  

  return (
    <div>
      <Grid container spacing={2} style={{ maxWidth: "73%", margin: "auto" }}>
        <Grid item xs={12}>
          <Paper elevation={1} style={{ padding: "20px", backgroundColor: context.appTheme === 'light' ? 'white' : '#454a4a', boxShadow: context.appTheme === 'light' ? "10px 4px 18px rgb(213 235 234)":"10px 4px 18px #454a4a" }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar
                  sx={{
                    width: "100px",
                    height: "100px",
                    fontSize: "36px",
                    backgroundColor: generateColorFromInitial(searchResult.first_name[0]),
                  }}
                  src={searchResult.profile_picture_url}
                >
                  {searchResult.first_name.charAt(0)}{searchResult.last_name.charAt(0)} 
                </Avatar>
              </Grid>

              <Grid item xs={8}>
                <Typography variant="subtitle1" color="primary">
                  {searchResult.school}
                </Typography>
                <Typography variant="h6" fontWeight="bold" style={{color: context.appTheme === 'light' ? 'black': 'white'}}>
                  {searchResult.first_name} {searchResult.last_name}
                </Typography>

                <div style={{ marginBottom: "5px" }}>
                  <Typography variant="body2" color="textSecondary" style={{color: context.appTheme === 'light' ? 'black': 'white'}}>
                    Class of {searchResult.graduation_year}
                    &nbsp;•&nbsp;
                    Tutoring Method: {searchResult.tutoring_method}
                    &nbsp;•&nbsp;
                    {searchResult.email}
                  </Typography>
                </div>

                <Divider />

                <Typography variant="body2" sx={{ color: "rgb(100 186 186)", marginY: "7px" }}>
                  Pricing: ${searchResult.tutoring_rate}/hr
                </Typography>
              </Grid>

              <Grid item xs>
                <Typography>
                  {!moreInfo && searchResult.introduction.length > 240 ? (
                    <Typography style={fadeDownStyle} >
                      {searchResult.introduction.substring(0, 240)}
                    </Typography>
                  ) : (
                    <Typography style={{color: context.appTheme === 'light' ? 'black': 'white'}}>
                    {searchResult.introduction}
                    
                    </Typography>
                  )}
                </Typography>
                {!moreInfo && searchResult.introduction.length > 240 && (
                  <Typography
                    component="span"
                    sx={{ color: "blue", cursor: "pointer" }}
                    onClick={() => showFullDescription(searchResult.introduction)}
                  >
                    See More Info
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>


    </div>
  );
}

export default SearchListItem;
