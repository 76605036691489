import { Button, Grid, Paper, Typography } from "@mui/material";
import React,{useEffect, useState} from "react";
import API from "../../utils/api"
import TextField from '@material-ui/core/TextField';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';



function EditServiceCatalogueComponent ({ servicedata }) {
    const token = localStorage.getItem("jwt")
    const [isEditable, setIsEditable] = useState(false)
    const [subjects, setSubjects] = useState([])
    const [loading, isLoading] = useState(true);
    const styles = {
        paper: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minHeight: "200px",
            width: "70%",
        },
        paperContainer: {
            position: "relative",
            height: "225px",
        },
        button: {
            width: "100%"
        }
    }
    const handleEdit = () => {
        setIsEditable(true)
    }
    const handleSave = () => { 
        setIsEditable(false)
    }
    const handleDelete = () => {
        API.delete(`servicecatalogue/${servicedata.id}`)
        .then(response => {
            console.log(response.data)
            window.location.href = "./editservicecatalogue"
        })
    }
    useEffect(() => {
        API.get(`subjects/${servicedata.subject_id}`)
        .then(response => {
            console.log("subject id from edit service catalogue",response.data)
            setSubjects(response.data)
            isLoading(false)
        })
    },[])
    
    return(
        <div style={styles.paperContainer}>
            <Paper elevation={4} style = {styles.paper}>
                <Grid container>
                    <Grid item xs={4} align = "center">
                    {loading ? <Box sx={{ display: 'flex' }}><CircularProgress /></Box> : <Typography variant="h5">{subjects}</Typography>}
                    </Grid>
                    <Grid item xs={8}>
                    <Typography variant="h5">Tutoring Rate per hour: <TextField disabled={!isEditable} defaultValue={servicedata.tutoring_rate}/>$</Typography>
                    <Typography variant="h5">Tutoring Method: <TextField disabled={!isEditable} defaultValue={servicedata.tutoring_method}/></Typography>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                        <Button variant="outlined" style={styles.button} onClick={handleEdit} endIcon={<EditIcon/>} fullWidth>Edit</Button>
                        </Grid>
                        <Grid item xs={4}>
                        <Button variant="outlined" style={styles.button} onClick={handleSave} fullWidth>Save</Button>
                        </Grid>
                        <Grid item xs={4}>
                        <Button variant="outlined" style={styles.button} onClick={handleDelete}color="error" fullWidth>Stop Tutoring</Button>
                        </Grid>
                    </Grid>
                    
                    
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default EditServiceCatalogueComponent;