import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {ThemeContext} from '../../App'
import API from '../../utils/api'
import Grid from "@mui/material/Grid";
import useNavBarStyles from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import { Paper } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import InfoIcon from "@mui/icons-material/Info";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import QuizIcon from '@mui/icons-material/Quiz';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ShareComponent from "../ShareComponent";
import ToggleTheme from "../ToggleTheme";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(0),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,

        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        }),
    })
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
}));

function NavbarMobileAuth() {
    let navigate = useNavigate();
    let location = useLocation();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    let NavBarClass = useNavBarStyles();
    const jwttoken = localStorage.getItem('jwt')
    const context = useContext(ThemeContext)
    const [userdata, setuserdata] = useState(null)

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleLogout = (event) => {
        localStorage.removeItem('jwt')
        window.location.href = "./"
    }

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        API.post('decodetoken', { token: jwttoken })
            .then(response => {
                setuserdata(response.data)
                console.log('response from mobile navbar page decodetoken', response.data)
            })
            .catch(error => {
                console.log("Status:", error.response.status);
                console.log("Error message:", error.message);
                console.log("Error data:", error.response.data);
            })
    }, [])

    const handleMenu = (item) => {
        console.log("menu item", item);
        if (item == "About Us") {
            navigate("/about");
        } else if (item == "Sign In") {
            navigate("/signin");
        }
        else if (item == "Start Tutoring") {
            navigate("/signup");
        }
        else if (item == "Account Settings") {
            navigate('/settings')
        }
        else if (item == "How it works") {
            navigate('/howitworks')
        }
        else if (item == "FAQ") {
            navigate('/faq')
        }
        else if (item == "Dashboard") {
            navigate(`/dashboard/${Array.isArray(userdata) ? userdata[0].id : userdata.id}`)
        }
    };

    const openHomePage = () => {
        if (location.pathname != "/") {
            navigate("/", { replace: true });
        }
    };

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} style={{backgroundColor: context.appTheme === 'light' ? 'white' : '#282828' }}>
                <Paper elevation="8">
                    <Toolbar>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid className={NavBarClass.homePageGrid} item>
                                <Box
                                    onClick={openHomePage}
                                    className={NavBarClass.homePageButton}
                                    component="img"
                                    sx={{
                                        height: 59,
                                        width: 180,
                                        maxHeight: { xs: 30, md: 167 },
                                        maxWidth: { xs: 90, md: 250 },
                                    }}
                                    src={require("../../images/product_logo.png")}
                                />
                            </Grid>

                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={handleDrawerOpen}
                                    sx={{ ...(open && { display: "none" }) }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Paper>
            </AppBar>
            <Main open={open}>
                <DrawerHeader />
            </Main>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {["About Us", "Start Tutoring",].map((text, index) => (
                        <ListItem
                            key={text}
                            onClick={() => handleMenu(text)}
                            disablePadding
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    {index % 2 === 0 ? <InfoIcon /> : <PersonAddAlt1OutlinedIcon />}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>

                    ))}
                    <List>
                        {["Dashboard"].map((text, index) => (
                            <ListItem
                                key={text}
                                onClick={() => handleMenu(text)}
                                disablePadding
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        {<PersonAddAlt1OutlinedIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>

                        ))}
                        {["FAQ", "How it Works"].map((text, index) => (
                            <ListItem
                                key={text}
                                onClick={() => {
                                    if (text === "Log out") {
                                        handleLogout();
                                    } else {
                                        handleMenu(text);
                                    }
                                }}
                                disablePadding
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        {index % 3 === 0 ? <QuestionAnswerIcon /> : index % 3 === 1 ? <QuizIcon /> : <ShareComponent />}
                                    </ListItemIcon>
                                    <ListItemText primary={text} style={text === "Log out" ? { color: 'red' } : {}} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {["Account Settings", "Log out", "Toggle Theme"].map((text, index) => (
                            <ListItem
                                key={text}
                                onClick={() => {
                                    if (text === "Log out") {
                                        handleLogout();
                                    } else {
                                        handleMenu(text);
                                    }
                                }}
                                disablePadding
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        {index % 3 === 0 ? <SettingsOutlinedIcon /> : index % 3 === 1 ? <LogoutOutlinedIcon style={{ color: 'red' }} /> : <ToggleTheme />}
                                    </ListItemIcon>
                                    <ListItemText primary={text} style={text === "Log out" ? { color: 'red' } : {}} />
                                </ListItemButton>

                            </ListItem>
                        ))}
                    </List>

                </List>

            </Drawer>
        </Box>
    );
}

export default NavbarMobileAuth;