import "./App.css";
import AboutPage from "./containers/AboutPage";
import HomePage from "./containers/HomePage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SearchResultPage from "./containers/SearchResultsPage";
import SignUpPage from "./containers/SignUpPage";
import RegisterPage from "./containers/RegisterPage";
import LoginPage from "./containers/LoginPage";
import PrivacyPage from "./containers/PrivacyPage";
import TermsPage from "./containers/TermsPage";
import AdminHub from "./containers/AdminHub";
import ProtectedRoute from "./utils/ProtectedRoute";
import ProtectedAuthRoute from "./utils/ProtectedAuthRoute";
import SettingsPage from "./containers/SettingsPage";
import ProtectedAdminRoute from "./utils/ProtectedAdminRoute";
import EditServiceCatalogue from "./containers/EditServiceCatalogue";
import ChangePassword from "./containers/ChangePassword";
import React, { useState, useEffect, useMemo, createContext } from "react";
import API from "./utils/api";
import { UserContext } from "./utils/UserContext";
import UnauthorizedPage from "./containers/UnauthorizedPage";
import FAQ from "./containers/FAQ";
import HowItWorks from "./containers/HowItWorks";
import Viewall from "./containers/ViewAll";
import Dashboard from "./containers/Dashboard";


export const ThemeContext = createContext(null)

function App() {
  const auth = localStorage.getItem('jwt');
  const [userdata, setuserdata] = useState(false)
  const [user, setUser] = useState(null)
  const [loading, setloading] = useState(true)
  const value = useMemo(() => ([user, setUser]), [user, setUser])
  const [appTheme, setAppTheme] = useState('light');

  useEffect(() => {
    API.post('decodetoken', { token: auth })
      .then(response => {
        console.log("response from app.js", response.data)
        setUser(response.data)
        setloading(false)
      })
      .catch(error => {
        console.log("Status:", error.response.status)
        console.log("Error message:", error.message);
        console.log("Error data:", error.response.data);
      })
  }, [])

  const toggleTheme = () => {
    setAppTheme((curr) => (curr === 'light' ? 'dark' : 'light'))
  }
  return (
    <ThemeContext.Provider value={{ appTheme, toggleTheme }}>
      <UserContext.Provider value={value}>
        <BrowserRouter forceRefresh={true}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/search/:id" element={<SearchResultPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path='/noauth' element={<UnauthorizedPage />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/howitworks" element={<HowItWorks />} />
            <Route path="/admin" element={<AdminHub />} />
            <Route path="/viewall" element={<Viewall />} />

            <Route element={<ProtectedRoute />}>
              <Route path="/signup" element={<SignUpPage loading={loading} />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/editservicecatalogue" element={<EditServiceCatalogue />} />
              <Route path="/changepassword" element={<ChangePassword />} />
              <Route path="/dashboard/:id" element={<Dashboard />} />

            </Route>

            <Route element={<ProtectedAdminRoute loading={loading} data={user} />}>
            </Route>



            <Route element={<ProtectedAuthRoute />}>
              <Route path="/signin" element={<LoginPage />} />
            </Route>

          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    </ThemeContext.Provider>






  );
}

export default App;
