import React, {useState, useEffect, useContext} from "react";
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import { Grid, Paper, Avatar, TextField, Button } from "@mui/material";
import API from "../../utils/api";




function MakeAdminComponent() {

    const paperStyle = {padding : 20, height: '30vh' , width: '400px', margin: '20px auto'}
    const avatarStyle = {backgroundColor: 'rgb(122 142 141)'}
    const [user_id, setuser_id] = useState(null)
    const payload = {account_type: "admin"}
    

    const makeAdmin = () => {
        API.put(`makeAdmin/${user_id}`, payload)
        .then((res) =>{
            console.log("response from makeAdmin:", res.data)
        })
        .catch((error) => {
            console.log("Error status:", error.response.status);
            console.log("Error message:", error.message);
            console.log("Error data:", error.response.data);
        })
    }

return(
    <div>
    
    <Grid>
        <Paper elevation={10} style={paperStyle}>
        <Grid align = 'center'>
        <Avatar style={avatarStyle}><AdminPanelSettingsOutlinedIcon /></Avatar>
        <h2>Make Admin 🪄</h2>
            <div>
            <TextField variant = 'standard' label='Enter Username' name='login_id' fullWidth onChange={
            (e) => {
            console.log("changed")
            setuser_id(e.target.value)
            }
            }/>
            </div><br/>
            <Button variant="contained" onClick={makeAdmin} fullWidth >Adminify</Button>
        </Grid>
        </Paper>
        </Grid>
    </div>
)
}



export default MakeAdminComponent;
