import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import CourseList from "../../dataFiles/courseList.json";
import useStyles from "./styles";
import { ThemeContext } from "@emotion/react";

function SearchBar({ handleChange, searchString, option, label, justifyContent, labelColor }) {
  const courseList = CourseList;
  const classes = useStyles();
  const context = useContext(ThemeContext)

  // Define a custom style for the label
  const customLabelStyle = {
    color: labelColor, // Change this to the desired label color
  };
  return (
    <div>
      <Grid
        container
        className={classes.searchBarGrid}
        direction="row"
        justifyContent={justifyContent}
      >
        <Autocomplete
          value={courseList.find((option) => option.name === searchString)}
          className={classes.searchBar}
          id="free-solo-demo"
          PaperProps={{ backgroundColor: 'pink'}} 
          freeSolo
          getOptionLabel={(option) => option.name}
          onChange={handleChange}
          options={option}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              className="textField"
              style={{ backgroundColor: labelColor === 'black'? "rgba(255,255,255,1)":"#40414f", borderRadius: '5px', width: "100%" }}
              InputLabelProps={{
                style: customLabelStyle // Apply custom label style here
              }}
            />
          )}
        />
      </Grid>
    </div>
  );
}

export default SearchBar;
