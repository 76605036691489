import React, { Component, useRef } from 'react';
import API from '../../utils/api'
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useNavigate, useLocation } from "react-router-dom";
import bcrypt from 'bcryptjs';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';






function RegisterComponentwithNavigate() {
    const navigate = useNavigate();
    
    const handleSignIn = () => {
        navigate('/signin')
    }
    return(
        <RegisterComponent handleSignIn = {handleSignIn} />
    )
}

class RegisterComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            login_id: '',
            password_hash: '',
            first_name: '',
            last_name: '',
            grad_year: '',
            email: '',
            school_district_id: '92e772cf-8829-498b-8f3c-2bd61ed77002',
            introduction: '',
            tutor_status: 'none',
            school_id: 'b803d523-e57d-4e7e-9dd9-6230440c62af',
            school: '',
            account_type: "none"
        }
    }

    

    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
    }
    submitHandler = e => {
        
        e.preventDefault()
        const salt = bcrypt.genSaltSync(10);
        const hash = bcrypt.hashSync(this.state.password_hash, salt)
        console.log('hash is',hash)
        this.state.password_hash = hash;
        
        console.log(this.state)
        API.post('users',this.state)
            .then(response => {
                console.log("response is:" , response)
                this.handleClick();
            })
            .catch(error => {
                console.log(error)
                this.handleErrorClick();
            }) 

        
        

            
    }  

    handleClick = () => {
        this.setState({ open: true });
    };
    handleErrorClick = () => {
        this.setState({ errorOpen: true });
    };

    state = {
        open: false,
        errorOpen: false
    };

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
                return;
            }
        this.setState({ open: false, errorOpen: false  });
    };

    render() {
        const { handleSignIn } = this.props;
        const {login_id,first_name,last_name,grad_year,email,school_district_id,introduction,tutor_status,school_id,school,tutoring_method, password_hash} = this.state
        const paperStyle = {padding : 20, height: '85vh' , width: '400px', margin: '20px auto'}
        const avatarStyle = {backgroundColor: '#1bbd7e'}
        

        const classes = [
            {
                value: '2023',
                label: '2023',
            },
            {
                value: '2024',
                label: '2024',
            },
            {
                value: '2025',
                label: '2025',
            },
            {
                value: '2026',
                label: '2026',
            },
            ];
        const schools = [
            {
                value: 'Westview High School',
                label: 'Westview High School',
            },
            {
                value: 'Del Norte High School',
                label: 'Del Norte High School',
            },
            {
                value: 'Mount Carmel High School',
                label: 'Mount Carmel High School',
            },
        ];
        return(
            <div>
                
                <Grid>
                    <Paper elevation={10} style={paperStyle}>
                        <Grid align = 'center'>
                            <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>
                            <h2>Sign up</h2>
                        </Grid>
                        <form onSubmit={this.submitHandler}>
                        <div>
                        <TextField label='Create Username' name='login_id' value={login_id} onChange={this.changeHandler} variant = 'standard' placeholder='Create Username' required fullWidth/>
                        </div>
                        <div>
                        <TextField label = 'Create Password' name='password_hash' type='password' value={password_hash}  onChange = {this.changeHandler} variant = 'standard' placeholder='Enter Password' required fullWidth/>
                        </div>
                        <br/>
                        <Grid container>
                            <Grid item xs={6}>
                                <div>
                                    <TextField label = 'First Name' name='first_name' value={first_name} onChange={this.changeHandler} variant = 'outlined' placeholder='Enter First Name' required fullWidth/>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div>
                                    <TextField name='last_name' label = 'Last Name' value={last_name} onChange={this.changeHandler} variant = 'outlined' placeholder='Enter Last Name' required fullWidth/>
                                </div>
                            </Grid>
                        </Grid>
                        <div>
                            <TextField name='grad_year' label = 'Graduation Year' value={grad_year} onChange={this.changeHandler} variant = 'outlined' placeholder='Enter Grad year' required fullWidth/>
                    </div>
                    <div>
                    <TextField name='email' label = 'Email' value={email} onChange={this.changeHandler} variant = 'standard' placeholder='Enter email' required fullWidth/>
                    </div>
                    <br/>
                    {/* <div>
                    <TextField
                    id="outlined-multiline-static"
                    label="Introduction"
                    multiline
                    rows={7}
                    name='introduction'
                    value={introduction}
                    onChange={this.changeHandler}
                    fullWidth
                    />
                    </div> */}
                    <div>
                    <TextField
                        id="Schoollist"
                        select
                        helperText="Please select your school"
                        variant="standard"
                        defaultValue = 'Westview High School'
                        name='school'
                        value={school}
                        onChange={this.changeHandler}
                        fullWidth
                
                    >
                    {schools.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
                    </TextField>
                    </div>
                    <FormControlLabel control={<Checkbox required/>} label='I agree to the Terms and Conditions'/>
                    <FormControlLabel control={<Checkbox required/>} label='I agree to the Privacy Policy'/>
                    <Button type = 'submit' color = 'primary' fullWidth variant='contained' >Register</Button>
                    <Button fullWidth variant='outlined' onClick={handleSignIn} style={{
                        marginTop: '10px'
                    }}>Already have an account? Sign in</Button>
                    
                    </form>
                    <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
                        <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
                            Account Succesfully Created!
                        </Alert>
                    </Snackbar>

                    <Snackbar open={this.state.errorOpen} autoHideDuration={6000} onClose={this.handleClose}>
                        <Alert onClose={this.handleClose} severity="error" sx={{ width: '100%' }}>
                            There has been an error
                        </Alert>
                    </Snackbar>

                    </Paper>
                </Grid>
            </div>
        )
    }
    
    
}

export default RegisterComponentwithNavigate;