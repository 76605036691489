import { makeStyles } from "@mui/styles";

const useNavBarStyles = makeStyles({
  homePageGrid: {
    
    display: "flex",
    alignItems: "center",
  },
  homePageButton: {
    cursor: "pointer",
  },
});

export default useNavBarStyles;
