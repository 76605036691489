import react from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


function HowitWorksComponent (props) { 
    return(
        <div>
            <Card variant='outlined'>
            <CardContent>
            <Typography variant="h5" component="div">
                {props.step}
            </Typography>
            <Typography variant="body2">
                {props.text}
                
            </Typography>
            </CardContent>
            <CardActions>
            </CardActions>
            </Card>
        </div>
    )
}

export default HowitWorksComponent;