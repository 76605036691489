import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { ThemeContext } from "../../App";
import Navbar from "../../components/NavbarDesktop";
import NavbarMobile from "../../components/NavbarMobile";
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import SearchListItem from "../../components/SearchListItem";
import NoAuthSearchListItem from "../../components/NoAuthSearchListItem";
import SearchListItemMobile from "../../components/SearchListItemMobile";
import API from "../../utils/api";
import ToggleTheme from "../../components/ToggleTheme";



function SearchResultPage() {
  const { id } = useParams();
  const theme = useTheme();
  const location = useLocation();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const [searchResults, setSearchResults] = useState(false);
  const [isloading, setisloading] = useState(true);
  const context = useContext(ThemeContext)
  

  useEffect(() => {
    API.get(`searchUsers/${id}`)
      .then((response) => {
        setSearchResults(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching search results:", error);
        setisloading(false);
      });
  }, [id]);

  const useStyles = makeStyles({
    progress: {
      backgroundColor: "black", 
    },
  });

  const auth = localStorage.getItem("jwt");

  return (
    <div
      style={{
        minHeight: "100vh", 
        backgroundColor: context.appTheme === 'dark'
        ? "#1a1f1f" // Dark mode color
        : "rgb(239, 250, 250)" // Light mode color,
      }}
    >
      {auth ? (desktop ? <NavBarAuth /> : <NavbarMobileAuth />) : desktop ? <Navbar /> : <NavbarMobile />}

      {/* Background Image */}
      <img
        alt="background"
        style={{
          height: 170,
          width: "100%",
          backgroundColor: context.appTheme === 'dark'
        ? "#2f3434" // Dark mode color
        : "rgb(91, 164, 164)", // Light mode color,
          marginBottom: "0px",
          zIndex: "1",
          position: "absolute",
        }}
        src={require("../../images/bg-header-desktop.svg").default}
      />

      <div style={{ marginTop: "200px" }}>
      
        {isloading ? (
          <LinearProgress color="primary" />
        ) : searchResults && searchResults.length > 0 ? (
          searchResults.map((result) => {
            return auth ? (
              desktop ? (
                <SearchListItem searchResult={result} />
              ) : (
                <SearchListItemMobile searchResult={result} />
              )
            ) : desktop ? (
              <NoAuthSearchListItem searchResult={result} />
            ) : (
              <SearchListItemMobile searchResult={result} />
            );
          })
        ) : (
          <div style={{ textAlign: "center", color: context.appTheme === 'dark' ? 'white' : 'black' }}>
            Sorry, there are no current entries for{" "}
            <span style={{ color: "rgb(91, 164, 164)" }}>{id}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchResultPage;
