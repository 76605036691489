import React, { useState, useEffect, useContext } from "react";
import SignUpForm from "../../components/SignUpForm";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import NavbarMobile from "../../components/NavbarMobile";
import Navbar from "../../components/NavbarDesktop";
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import API from '../../utils/api'
import { LinearProgress } from "@mui/material";
import { ThemeContext } from "../../App";
import RefactoredSignUpForm from "../../components/RefactoredSignYpForm";





function SignUpPage({ loading }) {
  const auth = localStorage.getItem('jwt');
  const [userdata,setuserdata] = useState(false)
  const handleSignUpData = (data)=>{
      console.log("data = ",data);

  }
  useEffect(() => {
    API.post('decodetoken',{token: auth})
    .then(response => {
        setuserdata(response.data)
    })
    .catch(error => {
        console.log("Status:", error.response.status);
        console.log("Error message:", error.message);
        console.log("Error data:", error.response.data);
    })
    },[])
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const context = useContext(ThemeContext)
  //const [user, setUser] = useContext(UserContext);

  return (
    <div style={{
      backgroundColor: context.appTheme === 'dark' ? '#1a1f1f' : 'white',
      height: '100vh'
    }}>
      {auth ?
          (desktop ? <NavBarAuth/> : <NavbarMobileAuth/>) :
          (desktop ? <Navbar/> : <NavbarMobile/>)
      }

        {userdata && !loading ? (
          <RefactoredSignUpForm data={userdata} />
        ) : (
          <div style={{textAlign: "center"}}>
            <LinearProgress /><br />
            If the loading issue continues, reload the page
          </div>
        )}
    </div>
  );
}

export default SignUpPage;
