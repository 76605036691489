import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";

const useStyles = makeStyles(() => {
  const theme = createTheme();
  return {
    searchBarGrid: {
      marginTop: "10px",
    },

    searchBar: {
      width: "100%",
      maxWidth:"850px",
    },
    /*textField: {
      backgroundColor: "white",
    }*/
  };
});

export default useStyles;
