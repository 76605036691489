import React, { Component, useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import API from '../../utils/api';
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LockIcon from '@mui/icons-material/Lock';


function ChangePasswordComponent({ auth,data }) {
    console.log("login id",data.login_id)
    const paperStyle = { padding: 20, height: '80vh', width: '400px', margin: '20px auto', }
    const avatarStyle = { backgroundColor: 'rgb(24 118 210)' }
    const [currentpassword, Setcurrentpassword] = useState()
    const [newpassword, setnewpassword] = useState()
    const [open, setOpen] = React.useState(false);
    const [erroropen, SetErrorOpen] = React.useState(false);


    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
    }
    setOpen(false);
    SetErrorOpen(false);
    };

    const handleErrorClick = () => {
        SetErrorOpen(true);
    }
    const handlePassword = () => {
        const payload = {
            login_id: data.login_id,
            currentpassword: currentpassword,
            newpassword: newpassword
        }
        

        API.post('comparePasswords',payload)
        .then(response => {
            console.log(response.data)
            
        })
        .catch((error) => {
        console.log("Status:", error.response.status);
        console.log("Error message:", error.message);
        console.log("Error data:", error.response.data);
        })
    
        
    }

    useEffect(() => {
        console.log("this is what was passed down", data)
    },[data])
    return (

        <div>
            <Grid>
                <Paper elevation={10} style={paperStyle}>
                    <Grid align='center'>
                        <Avatar style={avatarStyle}><LockIcon /></Avatar>
                        <h2>Change Password</h2>
                    </Grid>
                    <div>
                        <TextField label='Current Password' name='password' variant='standard' placeholder='Enter Current Password' fullWidth onChange={
                            (e) => {
                                Setcurrentpassword(e.target.value)
                            }
                        } />
                    </div>
                    <div>
                        <TextField label='Enter New Password' name='newpassword' variant='standard' placeholder='Enter New Password' type='password' fullWidth onChange={
                            (e) => {
                                setnewpassword(e.target.value)
                            }
                        } />
                    </div>
                    <br />
                    <Button color='primary' fullWidth variant='contained' onClick={handlePassword}>Change Password</Button>
                </Paper>
            </Grid>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Password Changed
                </Alert>
            </Snackbar>

            <Snackbar open={erroropen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Passwords don't match
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ChangePasswordComponent;