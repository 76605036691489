import React, {useState} from "react";
import API from '../../utils/api'
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useNavigate, useLocation } from "react-router-dom";
import bcrypt from 'bcryptjs';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import AdComponent from "../AdComponent/adcomponent";
import CircularProgress from '@mui/material/CircularProgress';


function RegisterForm () {
    const [login_id, setlogin_id] = useState(null)
    const [password_hash, setpassword_hash] = useState(null)
    const [first_name, setfirst_name] = useState(null)
    const [last_name, setlast_name] = useState(null)
    const [grad_year, setgrad_year] = useState(null)
    const [email, setemail] = useState(null)
    const [school_district_id, setschool_district_id] = useState('92e772cf-8829-498b-8f3c-2bd61ed77002')
    const [introduction, setintroduction] = useState(null)
    const [tutor_status, settutor_status] = useState("none")
    const [school_id, setschool_id] = useState("b803d523-e57d-4e7e-9dd9-6230440c62af")
    const [school, setschool] = useState(null)
    const [account_type, setaccount_type] = useState("none")
    const [buttonloading, setbuttonloading] = useState(false)

    const [open, setopen] = useState(false)
    const [error, seterror] = useState(false)

    const navigate = useNavigate();
    

    const payload = {
        login_id: login_id,
        password_hash: password_hash,
        first_name: first_name,
        last_name: last_name,
        grad_year: grad_year,
        email: email,
        school_district_id: school_district_id,
        introduction: introduction,
        tutor_status: tutor_status,
        school_id: school_id,
        school: school,
        account_type: account_type
    }

    const submitHandler = (e) => {
        setbuttonloading(true)
        console.log("clicked")
        e.preventDefault()
        const salt = bcrypt.genSaltSync(10);
        const hash = bcrypt.hashSync(password_hash, salt)
        console.log('hash is',hash)
        payload.password_hash = hash
        
        console.log("payload from refactored register component: ",payload)
        API.post('users',payload)
            .then(response => {
                console.log("response is:" , response)
                setopen(true)
                setbuttonloading(false)
                navigate("/signin")
            })
            .catch(error => {
                console.log(error)
                console.log('there has been an error')
                seterror(true)
                setbuttonloading(false)
            }) 
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        seterror(false)
        setopen(false)
    }
    
    const paperStyle = {padding : 20, height: '85vh' , width: '400px', margin: '20px auto'}
    const avatarStyle = {backgroundColor: '#1bbd7e'}
    const classes = [
        {
            value: '2023',
            label: '2023',
        },
        {
            value: '2024',
            label: '2024',
        },
        {
            value: '2025',
            label: '2025',
        },
        {
            value: '2026',
            label: '2026',
        },
        ];
    const schools = [
        {
            value: 'Westview High School',
            label: 'Westview High School',
        },
        {
            value: 'Del Norte High School',
            label: 'Del Norte High School',
        },
        {
            value: 'Mount Carmel High School',
            label: 'Mount Carmel High School',
        },
    ];
    return (
<div>
        <Grid>
            <Paper elevation={10} style={paperStyle}>
                <Grid align = 'center'>
                    <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>
                    <h2>Sign up</h2>
                </Grid>
                <form >
                <div>
                <TextField label='Create Username' name='login_id'  onChange={(e) => {setlogin_id(e.target.value)}} variant = 'standard' placeholder='Create Username' required fullWidth/>
                </div>
                <div>
                <TextField label = 'Create Password' name='password_hash' type='password'  onChange={(e) => {setpassword_hash(e.target.value)}} variant = 'standard' placeholder='Enter Password' required fullWidth/>
                </div>
                <br/>
                <Grid container>
                    <Grid item xs={6}>
                        <div>
                            <TextField label = 'First Name' name='first_name' onChange={(e) => {setfirst_name(e.target.value)}} variant = 'outlined' placeholder='Enter First Name' required fullWidth/>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <TextField name='last_name' label = 'Last Name' onChange={(e) => {setlast_name(e.target.value)}} variant = 'outlined' placeholder='Enter Last Name' required fullWidth/>
                        </div>
                    </Grid>
                </Grid>
                <div>
                    {/* <TextField
                    id="standard-select-currency"
                    select
                    helperText="Please enter your grad. year"
                    variant="standard"
                    defaultValue="2023"
                    name='grad_year'
                    onChange={(e) => {setgrad_year(e.target.value)}}
                    fullWidth
                    required
                >
                    {classes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
    ))}
            </TextField> */}<br/>
            <TextField name='grad_year' label = 'Graduation Year' onChange={(e) => {setgrad_year(e.target.value)}} variant = 'outlined' placeholder='Enter Graduation Year' required fullWidth/>
            </div>
            <div>
            <TextField name='email' label = 'Email' onChange={(e) => {setemail(e.target.value)}} variant = 'standard' placeholder='Enter email' required fullWidth/>
            </div>
            <br/>
            {/* <div>
            <TextField
            id="outlined-multiline-static"
            label="Introduction"
            multiline
            rows={7}
            name='introduction'
            value={introduction}
            onChange={this.changeHandler}
            fullWidth
            />
            </div> */}
            <div>
            <TextField
                id="Schoollist"
                select
                helperText="Please select your school"
                variant="standard"
                defaultValue = 'Westview High School'
                name='school'
                onChange={(e) => {setschool(e.target.value)}}
                fullWidth
        
            >
            {schools.map((option) => (
            <MenuItem key={option.value} value={option.value}>
            {option.label}
            </MenuItem>
        ))}
            </TextField>
            </div>
            <FormControlLabel control={<Checkbox required/>} label='I agree to the Terms and Conditions'/>
            <FormControlLabel control={<Checkbox required/>} label='I agree to the Privacy Policy'/>
            <Button onClick={submitHandler} color = 'primary' disabled = {buttonloading} fullWidth variant='contained' >{buttonloading? <CircularProgress size={20}/>: "Sign up"}</Button>
            <Button fullWidth variant='outlined' onClick={() => {navigate('/signin')}} style={{
                marginTop: '10px'
            }}>Already have an account? Sign in</Button>
            <AdComponent />

            
            </form>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Account Succesfully Created!
                </Alert>
            </Snackbar>

            <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    There has been an error
                </Alert>
            </Snackbar>

            </Paper>
        </Grid>
        </div>
    )
}

export default RegisterForm;