import React from "react";
import FAQComponent from "../../components/FAQComponent";
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import NavbarMobile from "../../components/NavbarMobile";
import Navbar from "../../components/NavbarDesktop";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchListItemMobile from "../../components/SearchListItemMobile";

function FAQ () { 
    const token = localStorage.getItem('jwt')
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));

    return(
        <div>
            {token ?
            (desktop ? <NavBarAuth/> : <NavbarMobileAuth/>) :
            (desktop ? <Navbar/> : <NavbarMobile/>)
            }
            <FAQComponent question="Who can see your profile?" answer="The Tutors’ basic profile such as name, tutoring method, school name is visible in tutor search results. The personally identifiable information is visible only for signed in users." />
            <FAQComponent question="Do I have to use my real name?" answer="The tutors and tutees are connected with good intent, respect for privacy. You will use your real name just like when you introduce yourself to your fellow students." />
            <FAQComponent question="Will there be disclosure of my contacts?" answer="We will disclose your contact information only for signed in users. As a user of this platform, you are responsible for making sure that the tutors and tutees are vetting each other’s information." />
            <FAQComponent question="What is tutor vetting process?" answer="Once the user signs up as tutor, the platform admin will do basic vetting  by contacting prospective tutor by email, phone and then onboard the tutor. The tutor and tutee will also need to ensure that both parties have good intent." />
            <FAQComponent question='Privacy and data security' answer='We do not sell your data, Every user sign up with their real name. Just like in person. Your login credentials are protected by password and encrypted by HTTPS' />
            
        </div>
    )
}

export default FAQ;