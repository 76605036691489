import React,{useContext, useEffect} from 'react'
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import QuizIcon from '@mui/icons-material/Quiz';
import Grid from "@mui/material/Grid";
import useNavBarStyles from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import { Paper, ToggleButton } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import InfoIcon from "@mui/icons-material/Info";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ShareComponent from "../ShareComponent";
import {ThemeContext} from '../../App'
import ToggleTheme from '../ToggleTheme';

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,

    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function PersistentDrawerRight() {
  let navigate = useNavigate();
  let location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  let NavBarClass = useNavBarStyles();
  const context = useContext(ThemeContext)

  useEffect(() => {
    console.log('context')
  })
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (item) => {
    console.log("menu item", item);
    if (item == "About Us") {
      navigate("/about");
    } else if (item == "Sign In") {
      navigate("/signin");
    }
    else if (item == "Start Tutoring") {
      navigate("/signup");
    }
    else if (item == "Sign up") {
      navigate('/register')
    }
    else if (item == "How it Works") {
      navigate('/howitworks') 
    }
    else if (item == "FAQ") {
      navigate('/faq')
    }
  };

  const openHomePage = () => {
    if (location.pathname != "/") {
      navigate("/", { replace: true });
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{backgroundColor: context.appTheme === 'light' ? 'white' : '#282828' }}>

          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{color: context.appTheme === 'light' ? 'black' : 'white'}}
            >
              {context.appTheme === 'dark' ?<Grid className={NavBarClass.homePageGrid} item>
                <Box
                  onClick={openHomePage}
                  className={NavBarClass.homePageButton}
                  component="img"
                  sx={{
                    height: 59,
                    width: 180,
                    maxHeight: { xs: 30, md: 167 },
                    maxWidth: { xs: 90, md: 250 },
                  }}
                  src={require("../../images/logoDark.png")}
                />
              </Grid> :  <Grid className={NavBarClass.homePageGrid} item>
                <Box
                  onClick={openHomePage}
                  className={NavBarClass.homePageButton}
                  component="img"
                  sx={{
                    height: 59,
                    width: 180,
                    maxHeight: { xs: 30, md: 167 },
                    maxWidth: { xs: 90, md: 250 },
                  }}
                  src={require("../../images/product_logo.png")}
                />
              </Grid>}
              
              

              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  sx={{ ...(open && { display: "none" }) }}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>

      </AppBar>
      <Main open={open}>
        <DrawerHeader />
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader style={{
          backgroundColor: context.appTheme === 'dark' ? '#282828' : 'white'
        }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon style={{
                color: context.appTheme === 'dark' ? 'white': 'black'
              }}/>
            ) : (
              <ChevronRightIcon style={{
                color: context.appTheme === 'dark' ? 'white': 'black'
              }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List style={{
          backgroundColor: context.appTheme === 'dark' ? '#282828' : 'white',
          height: '100vh'
        }}>
          {["Sign In", "About Us",].map((text, index) => (
            <ListItem
              key={text}
              onClick={() => handleMenu(text)}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <LoginIcon style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }}/> : <InfoIcon style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }}/>}
                </ListItemIcon>
                <ListItemText primary={text}style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }} />
              </ListItemButton>
            </ListItem>
            
          ))}
          <List>
          {["Sign up"].map((text, index) => (
            <ListItem
              key={text}
              onClick={() => handleMenu(text)}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  {index % 1 === 0 ? <CreateOutlinedIcon style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }}/> : <CreateOutlinedIcon style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }}/>}
                </ListItemIcon>
                <ListItemText primary={text} style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }}/>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
          <List>
          {["Start Tutoring"].map((text, index) => (
            <ListItem
              key={text}
              onClick={() => handleMenu(text)}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  {index % 1 === 0 ? <PersonAddIcon style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }} /> : <PersonAddIcon style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }}/>}
                </ListItemIcon>
                <ListItemText primary={text} style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }}/>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <List>
          {["FAQ"].map((text, index) => (
            <ListItem
              key={text}
              onClick={() => handleMenu(text)}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  {index % 1 === 0 ? <QuestionAnswerIcon  style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }}/> : <QuestionAnswerIcon  style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }}/>}
                </ListItemIcon>
                <ListItemText primary={text} style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <List>
          {["How it Works", "Toggle Theme"].map((text, index) => (
            <ListItem
              key={text}
              onClick={() => handleMenu(text)}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }}>
                {index % 2 === 0 ? <QuizIcon /> : <ToggleTheme />}

                </ListItemIcon>
                <ListItemText primary={text} style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <List>
          {[""].map((text, index) => (
            <ListItem
              key={text}
              onClick={() => handleMenu(text)}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  
                    {index % 1 === 0 ? <ShareComponent /> : <ShareComponent/>}
                </ListItemIcon>
                <ListItemText primary={text} style={{
                  color: context.appTheme === 'dark' ? 'white': 'black'
                }}/>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        </List>
        
      </Drawer>
    </Box>
  );
}
