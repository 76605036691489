import React, { Component, useState, useEffect, useRef, useContext } from 'react';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from "react-router-dom";
import TextField from '@mui/material/TextField';
import API from '../../utils/api';
import Grid from "@mui/material/Grid";
import { Paper, Collapse } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Avatar from '@mui/material/Avatar';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import AdComponent from '../AdComponent/adcomponent';
import { UserContext } from '../../utils/UserContext';
import CloseIcon from '@mui/icons-material/Close';
import AlertTitle from '@mui/material/AlertTitle';
import emailjs from '@emailjs/browser';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeContext } from '../../App';

function LoginComponent() {
    const location = useLocation();
    const formRef = useRef();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const [forgotpwd, setforgotpwd] = useState(false)
    const [buttonloading, setbuttonloading] = useState(false)
    const [emailstate, setemailstate] = useState(false)
    const context = useContext(ThemeContext)
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleSignUp = () => {
        navigate('/register')
    }
    const handleHome = () => {
        navigate("/")
    }
    const [login_id, Setlogin_id] = useState();
    const [password_hash, Setpassword_hash] = useState();
    const [open, setOpen] = React.useState(false);
    const [erroropen, SetErrorOpen] = React.useState(false);
    const [userData, SetuserData] = useState([])
    const [user, setuser] = useContext(UserContext)
    const [email, setemail] = useState(null)
    const buttonRef = useRef(null);

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("clicked")
    
    emailjs.sendForm('service_muqpm9r', 'template_hypm5fn', formRef.current, 'ifgPRbktchuFx47I1')
        .then((result) => {
            console.log(result.text);
            console.log("message sent!")
            handleEmailClick();
        }, (error) => {
            console.log(error.text);
            
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    function handleKeyDown(event) {
        if (event.key === "Enter") {
        buttonRef.current.click();
        }
    }
    const handleClick = () => {
        setOpen(true);
        SetErrorOpen(false)
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
    }
    setOpen(false);
    SetErrorOpen(false);
    setemailstate(false)
    };

    const handleErrorClick = () => {
        SetErrorOpen(true);
        
    }
    const handleEmailClick = () => {
        setemailstate(true)
    }

    

    const handlelogin = () => {
        setbuttonloading(true)
        console.log("login id", login_id)
        console.log("password_nohash:",password_hash)
        API.interceptors.request.use((config) => {
            console.log('Request:', config);
            return config;
        });
        const payload = {
            login_id: login_id,
            password_hash: password_hash
        }
        console.log("login payload",payload)

        // request to login the user

        API.post('login',payload)
        .then((response)=> {
            console.log('response from login',response.data)
            setuser(response.data)
            setbuttonloading(false)
            API.post('generateToken',response.data)
            .then((tokenresponse) => {
                console.log("userdata",userData);
                console.log("tokenresponse:",tokenresponse.data)
                localStorage.setItem('jwt', tokenresponse.data);
                    // setAuthToken(response.data)
                    // setIsAuthenticated(true)
                    
            })
            .catch((error) => {
                console.log("Error status:", error.response.status);
                console.log("Error message:", error.message);
                console.log("Error data:", error.response.data);
            })
            handleClick();
        })
        .catch((error) => {
            console.log("Error status:", error.response.status);
            console.log("Error message:", error.message);
            console.log("Error data:", error.response.data);
            handleErrorClick();
        });
        
        
    }    
    const paperStyle = {padding : 20, height: '80vh' , width: '400px', margin: '20px auto', backgroundColor: context.appTheme === 'dark' ? '#454a4a' : 'white'}
    const avatarStyle = {backgroundColor: 'rgb(24 118 210)'}
    const homeStyle = { cursor: "pointer",
    "&:hover": {
        textDecoration: "underline"
    }}

    return(
        <div>
            <Grid style={{
                backgroundColor: context.appTheme === 'dark' ? "#191e1e" : 'white',
                height: '100vh'
            }}>
                <Paper elevation={10} style={paperStyle}>
                <Grid align = 'center'>
                            <Avatar style={avatarStyle}><AccountCircleOutlinedIcon/></Avatar>
                            <h2 style={{color: context.appTheme === 'dark' ? "white" : "black"}}>Sign In</h2>
                </Grid>
                <div>
                    <TextField onKeyDown={handleKeyDown} label='Login ID' name='login_id' variant = 'standard' placeholder='Enter Login ID' fullWidth onChange={
                        (e) => {
                    Setlogin_id(e.target.value)
                    }
                }/>
            </div>
            <div>
                <TextField onKeyDown={handleKeyDown} label = 'Enter Password' name='password_hash' variant = 'standard' placeholder='Enter Password' type={showPassword ? 'text' : 'password'} endAdornment={
                <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                </InputAdornment>
            } fullWidth onChange={
                        (e) => {
                    Setpassword_hash(e.target.value)
                    }
                    }/>
            </div><br/>
            <Collapse in={open}>
            <Alert
            action={
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
                >
                <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            sx={{ mb: 2 }}
            >
            <AlertTitle>Sign in Sucess!</AlertTitle>
            Click here to return to the <strong onClick={handleHome} style={homeStyle}>home page</strong>
            </Alert>
        </Collapse>
        <Collapse in={erroropen}>
            <Alert
            action={
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
                >
                <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            severity="error"
            sx={{ mb: 2 }}
            >
            <AlertTitle>Error</AlertTitle>
            Wrong username or password
            </Alert>
        </Collapse>
        <Collapse in={emailstate}>
            <Alert
            action={
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
                >
                <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            severity="warning"
            sx={{ mb: 2 }}
            >
            We will send you your temprary password shortly
            </Alert>
        </Collapse>
            
            <Button onClick={handlelogin} color = 'primary' disabled={buttonloading} fullWidth variant='contained' ref={buttonRef}>{buttonloading? <CircularProgress size={20}/>: "Sign in"}</Button>
            <Button fullWidth variant='outlined' style={{marginTop: '10px'}} onClick={handleSignUp} >Dont have an account? Sign up</Button><br/>
            {forgotpwd? (null): (
                <Button fullWidth variant='outlined' style={{marginTop: '10px'}} onClick={() => {setforgotpwd(true)}} >Click here if you forgot your password</Button>
            )}
            <AdComponent />
            
            
            {forgotpwd ? (
                <form ref={formRef} onSubmit={sendEmail} style={{marginTop: "10px"}}>
                <TextField name='email' placeholder='Enter your email' fullWidth />
                <Button fullWidth variant='outlined' type='submit' >Submit</Button>
                </form>
            ): (null)}


            
            
                </Paper>
            </Grid> 
            
        </div>
    )
}

export default LoginComponent;