import react from 'react';
import NavBarAuth from '../../components/NavBarAuth';
import NavbarMobileAuth from '../../components/NavBarMobileAuth';
import Navbar from '../../components/NavbarDesktop';
import NavbarMobile from "../../components/NavbarMobile";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import HowitWorksComponent from '../../components/HowItWorks';

function TermsPage() {
    const theme = useTheme();
    const auth = localStorage.getItem('jwt');
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    return(
        <div>
            {auth ?
                (desktop ? <NavBarAuth/> : <NavbarMobileAuth/>) :
                (desktop ? <Navbar/> : <NavbarMobile/>)
            }
            This BoredTutors.com is Westview Highschool, San Diego student’s project. Please read these terms of service (“Agreement”) carefully before using the Platform & Services. By using or accessing the BoredTutors.com and its Content  (collectively called “Platform & Services”), you agree to become bound by all the terms and conditions of this Agreement. If you do not agree to all the terms and conditions of this Agreement, do not use the Platform and Services. The following are the terms and condition of the services access by You (“You or Member”):
Definitions
A. Partie
"You" and "your" refer to you, as a user of the Platform & Services. A "user" is someone who accesses, browses, crawls, scrapes, or in any way uses the Platform & Services. "We," "us," and "our" refer to Platform developer and operator.
B. Content
"Content" means text, images, photos, audio, video, location data, and all other forms of data or communication. "Your Content" means Content that you submit or transmit to, through, or in connection with the Site, such as ratings, reviews, compliments, invitations, check-ins, messages, and information that you publicly display or displayed in your account profile.

Usage of the Services
Subject to the terms and conditions of this Agreement, the Services offered are solely for Member’s own use, and not for the use or benefit of any third party. Services shall include, but not be limited to, any services performed by the platform for Member, as well as Content on the platform. We may change, suspend or discontinue the Services at any time, including the availability of any feature, database, or Content. Platform developer may also impose limits on certain features and services or restrict Member’s access to parts or all of the Services without notice or liability. Platform developer reserve the right, at its discretion, to modify these Terms & Conditions at any time by posting revised Terms & Conditions on the BoredTutors.com website and by providing notice via e-mail, where possible.  Member shall be responsible for reviewing and becoming familiar with any such modifications. Use of the Services by Member following such modification constitutes Member's acceptance of the terms and conditions of this Agreement as modified.
Registrations and Account Security
Your membership is non-transferable. You must not share your username and password with anyone else or allow anyone else to access the Services using your username and password. You are responsible for every use of the Platform & Services that occurs in conjunction with use of your username and password. You must use reasonable efforts to keep your username and password confidential. You must notify us as soon as you become aware of any unauthorized use of your username or password.
You will not provide false information, impersonate others without permission
You will not create more than one personal account
You will not indulge into unlawful activities on the platform or unlawful activities using Platform & Services. 
Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements
You will not Send spam or other unwelcomed communications to others
Act in an unlawful, libelous, abusive, obscene, discriminatory or otherwise objectionable manner
You will not Violate intellectual property rights of others, including patents, trademarks, trade secrets, copyrights or other proprietary rights
You will not post any unsolicited or unauthorized advertising, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation unauthorized by BoredTutors.com
You will not use manual or automated software, devices, scripts robots, other means or processes to access, “scrape,” “crawl” or “spider” the Platform & Services or any related data or information
You will not use Platform and Services if you are under age of 13. Certain countries may have different restriction. You are responsible for understanding the laws of land while making use of the Platform and Services
You will not use Platform and Services if you are a convicted sex offender
You will not display pornographic or sexually explicit material of any kind and in any form
You will not provide material that exploits people under the age of 18 in a sexual or violent manner
You will keep your contact information accurate and up-to-date
You will not transfer the account, or let unauthorized use of the Platform and Services without permission by BoredTutors.com
We reserve the right to remove or reclaim it if we believe it is appropriate (such as when a trademark owner complains about a username that does not closely relate to a user's actual name)
We try to keep the user information secure as much as possible. BoredTutors.com developers, operators  will make reasonable efforts to secure the platform, BoredTutors.com developers, operators  are not responsible or liable in any way for breach of security

Sharing of the profile information

Tutors full profile information is visible only to signed in users. 

Availability and Use of the Services
The Services will usually be available 24 hours a day, seven days a week. We may, however, need to interrupt or suspend your access to the Services for maintenance, technical or other reasons.
The Services will be updated, changed or removed from time to time, at our discretion, so we can’t guarantee that specific Services will always be available on the Platform.
You agree that BoredTutors.com developers, operators  will not be liable in any event to You or any other party for any suspension, modification, discontinuance or lack of availability of the Platform & Services.
Rights to Content; intellectual property
The Platform and its contents are intended solely for the use of the Members and may only be used in accordance with the terms of this Agreement. All materials displayed or action performed on the Platform, including, but not limited to text, graphics, logos, tools, photographs, images, illustrations, software or source code, audio and video, and animations that are produced by the BoredTutors.com are the property of BoredTutors.com and are protected by United States and international copyright laws.

You agree that we may access, store and use any information that you provide in accordance with the terms of the Privacy Policy and your privacy settings.

By submitting suggestions or other feedback regarding our Services to BoredTutors.com, you agree that BoredTutors.com can use and share (but does not have to) such feedback for any purpose without compensation to you.
You agree to only provide content or information if that does not violate the law nor anyone's rights (e.g., without violating any intellectual property rights or breaching a contract). You also agree that your profile information will be truthful. BoredTutors.com may be required by law to remove certain information or content in certain countries.

Restrictions
Member is responsible for all of his activity in connection with the Platform & Services. Any fraudulent, abusive, or otherwise illegal activity or any use of the Platform & Services or Content in violation of this Agreement may be grounds for termination of Member’s right to Services or to access the platform.
Use of the Platform & Services to violate the security of any computer network, crack passwords or security encryption codes, transfer or store illegal material including that are deemed threatening or obscene, or engage in any kind of illegal activity is expressly prohibited. Under no circumstances will Member use the Platform & the Service to (a) send unsolicited e-mails, bulk mail, spam or other materials to users of the platform or any other individual, (b) harass, threaten, stalk or abuse any person or party, including other users of the Platform, (c) create a false identity or to impersonate another person, or (d) knowingly post any false, inaccurate or incomplete material.
Warranty disclaimer
BoredTutors.com developers, operators has no special relationship with or fiduciary duty to Member. Member acknowledges that BoredTutors.com developers, operators has no control over, and no duty to take any action regarding: which users gains access to the Platform; which Member Content accessed via the Platform; what effects the Content may have on Member; how Member may interpret or use the Content; or what actions Member may take as a result of having been exposed to the Content. Member releases BoredTutors.com from all liability for Member having acquired or not acquired Content through the Platform. 
Although BoredTutors.com developers, operators will make reasonable efforts to store and preserve the material residing on the Platform, BoredTutors.com developers, operators are not responsible or liable in any way for the failure to store, preserve or access Member Content or other materials you transmit or archive on the Platform. You are strongly urged to take measures to preserve copies of any data, material, content or information you post or upload on the Platform.
By using this Platform, you acknowledge that BoredTutors.com developers, operators are not responsible or liable for any harm resulting from (1) use of the Platform & Services; (2) unauthorized disclosure of images, information or data that results from the upload, download or storage of content posted by Members; (3) the temporary or permanent inability to access or retrieve any Member Content from the Platform
Indemnity
Member will indemnify and hold BoredTutors.com developers and operators  harmless, including costs and attorneys' fees.
Limitation of liability
In no event shall BoredTutors.com, its developers and operators  be liable with respect to the Platform & Services for (a) any indirect, incidental, punitive, or consequential damages of any kind whatsoever; (b) damages for loss of use, profits, data, images, Member Content or other intangibles; (c) damages for unauthorized use, non-performance of the BoredTutors.com platform errors or omissions; or (d) damages related to downloading or posting Content. 
Termination
BoredTutors.com may terminate or suspend any and all Services and access to the website immediately, without prior notice or liability, if Member breaches any of the terms or conditions of this Agreement. Upon termination of Member's account, Member’s right to use the Services, and any Content will immediately ceases. Termination of Your access to and use of the Platform & Services shall not relieve Member of any obligations arising or accruing prior to such termination or limit any liability which Member otherwise may have to BoredTutors.com, including without limitation any indemnification obligations contained herein.
Privacy
Please review our Privacy Policy (link here), which governs the use of personal information on the website and to which Member agrees to be bound as a user of the Platform.
Miscellaneous
This Agreement (including the Privacy Policy), as modified from time to time, constitutes the entire agreement between You, BoredTutors.com developers, operators with respect to the subject matter hereof. This Agreement replaces all prior or contemporaneous understandings or agreements, written or oral, regarding the subject matter hereof. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.  BoredTutors.com developers, operators shall not be liable for any failure to perform its obligations hereunder where such failure results from any cause beyond BoredTutors.com developer’s reasonable control, including, without limitation, mechanical, electronic or communications failure or degradation. If any provision of this Agreement is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Agreement shall otherwise remain in full force and effect and enforceable. This Agreement is not assignable, transferable by Member except with BoredTutors.com developer’s prior written consent. BoredTutors.com may assign this Agreement in whole or in part at any time without Member’s consent. Applicable Laws
We control and operate in San Diego, CA, USA. You agree that the agreement is subject to jurisdiction of Federal District Court for the judicial district San Diego, CA, USA 

Amendments 
We will notify you before we make changes to the terms & conditions, and give opportunity to review the revised terms & condition before continuing to use our services. The revised terms will be updated on the BoredTutors.com website. Your continued use of the Platform & Services, following notice of the changes to our terms, policies or guidelines, constitutes your acceptance of our amended terms, policies or guidelines.

Date last revised: April 30, 2023

            {/* <HowitWorksComponent step='Step 1. Search for subjects that you need help with' text = 'Browse available student tutors in your school district and the subject you’re looking for.'/>
            <HowitWorksComponent step='Step 2. Get introduced' text = 'Connect with student tutors based on your specific needs.'/>
            <HowitWorksComponent step='Step 3. Hire the right tutor' text = "When you're ready, start taking tutoring."/> */}

        </div>
    )
}

export default TermsPage;