import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import "../../images/westview2.jpg";
import Box from "@mui/material/Box";
import Navbar from "../../components/NavbarDesktop";
import useStyles from "./styles";

import NavbarMobile from "../../components/NavbarMobile";
import SearchBar from "../../components/SearchBar";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import bgimg from "../../images/bgimg.jpg";
import Button from "@mui/material/Button";
import API from '../../utils/api';
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import AdComponent from "../../components/AdComponent/adcomponent";
import { ThemeContext } from "../../App";


function HomePage() {
  let navigate = useNavigate();
  let classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  console.log("desktop == ", desktop);
  const [subjects, setSubjects] = useState(false);
  const [shouldRender, setShouldRender] = useState(false)
  const auth = localStorage.getItem('jwt');
  const context = useContext(ThemeContext)

  useEffect(() => {
    API.get(`subjects`) //http:localhost:3000/test/subjects
      .then(response => {
        console.log("response == ", response.data);
        console.log('apptheme', context.appTheme)
        setSubjects(response.data);
      }).then(setShouldRender(true))
  }, []);

  const handleChange = (event, value) => {
    console.log("event == ", event);
    console.log("value == ", value);
    console.log("Subject id is:", value.id);
    console.log("Subjects:", subjects)
    navigate(`/search/${value.id}`, { state: { searchString: value } });
  };

  const handleAboutUs = () => {
    navigate("/about");
  };

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${bgimg})`,
          backgroundRepeat: "no-repeat",
          position: "fixed",
          backgroundSize: "cover",
          backgroundPosition: "center",
          flex: 1,
          width: "100%",
          height: "100%",
          resizeMode: "contain",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            minHeight: "100vh",
          }}
        >
          {auth ? (
            desktop ? <NavBarAuth /> : <NavbarMobileAuth />
          ) : (
            desktop ? <Navbar /> : <NavbarMobile />
          )}
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: desktop ? "100vh" : "67vh",
            }}
          >
            <Box
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
              }}
            >
              <Typography
                className={classes.tagLine}
                variant={desktop ? "h3" : "h4"}
                style={{ color: "white" }}
                gutterBottom
              >
                Find help from students in your school in{" "}
                <span style={{ color: context.appTheme === 'light' ?  "rgb(61,150,251)" : "rgb(61, 77, 251)"}}>seconds.</span>
              </Typography>

              <Grid container>
                <Grid container alignItems="center">
                  <Grid item xs={8}>
                    <SearchBar
                      handleChange={handleChange}
                      option={subjects}
                      shouldRender={shouldRender}
                      label="Enter course name"
                      justifyContent="right"
                      labelColor={context.appTheme === 'dark' ? 'white' : 'black'}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ marginTop: "10px" }}>
                    <Button
                      variant="contained"
                      style={{
                        width: desktop ? "180px" : "100px",
                        height: "57px",
                        backgroundColor: context.appTheme === 'dark' ? '#0f1923' : '#2376d2'
                      }}
                      onClick={() => navigate('/viewall')}
                    >
                      view all tutors
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </div>
      </div>
    </div>
  );

}

export default HomePage;
