import React, { useState, useEffect } from "react";
import NavBarAuth from "../../components/NavBarAuth";
import NavbarMobile from "../../components/NavbarMobile";
import Navbar from "../../components/NavbarDesktop";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NavbarMobileAuth from "../../components/NavBarMobileAuth";
import { Grid, Paper } from "@mui/material";
import API from '../../utils/api'
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from "react-router-dom"; 
import SettingsComponent from "../../components/SettingsComponent";
import CircularProgress from '@mui/material/CircularProgress';






function SettingsPage() {
    const token = localStorage.getItem('jwt')
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    const [userdata, setuserdata] = useState(null)
    const [loading, setloading] = useState(true)
    

    useEffect(() => {
        API.post('decodetoken',{token: token})
        .then(response => {
            setuserdata(response.data)
            setloading(false)
            console.log('response from settigs page decodetoken', response.data)
        })
        .catch(error => {
            console.log("Status:", error.response.status);
            console.log("Error message:", error.message);
            console.log("Error data:", error.response.data);
        })
    },[])
    return (
        <div>
        {token ?
            (desktop ? <NavBarAuth /> : <NavbarMobileAuth />) :
            (desktop ? <Navbar /> : <NavbarMobile />)
        }
        
        {/* {loading ? <CircularProgress /> : <SettingsComponent data={userdata}/>} */}
        {loading ? (<CircularProgress />) : (
            Array.isArray(userdata) ? (
                <SettingsComponent data={userdata[0]} />
            ) : (
                <SettingsComponent data={userdata} />
            )
        )}
        </div>
        
        
    )
}

export default SettingsPage;