import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  tagLine: {
    textAlign: "center",
    marginLeft: "5%",
    marginRight: "5%"
  },

  textfield__label: {
    color: "blue !important",
  },
});

export default useStyles;
