import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
function ContactForm(){
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(true);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_muqpm9r', 'template_sc4hc2c', form.current, 'ifgPRbktchuFx47I1')
        .then((result) => {
            console.log(result.text);
            console.log("message sent!")
        }, (error) => {
            console.log(error.text);
            
        });
    };
    
    return(
        <div style={{
            textAlign: "center"
        }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Message Sent!
        </Alert>
      </Snackbar>

      <Typography variant='h3'>Reach out to us:</Typography><br/>
      
      <form ref={form} onSubmit={sendEmail} style={{
        
      }}>
      <input style={{
        width: "40%",
        height: "40px",
        marginTop: "20px",
        paddingLeft: "10px",
        paddingRight: "10px",
        border: "1px solid #777",
        borderRadius: "14px",
        outline: "none"
      }} type="text" name="user_name" placeholder='Your name here'/><br/>
      <input style={{
        width: "40%",
        height: "40px",
        marginTop: "20px",
        paddingLeft: "10px",
        paddingRight: "10px",
        border: "1px solid #777",
        borderRadius: "14px",
        outline: "none"
      }} type="email" name="user_email" placeholder='Your email'/><br/>
      <textarea  style={{
        width: "40%",
        height: "150px",
        marginTop: "20px",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        border: "1px solid #777",
        borderRadius: "14px",
        outline: "none"
      }} name="message" placeholder='Message'/><br/> 
      <input style={{
        borderRadius: "20px",
        color: "#fff",
        marginTop: "18px",
        padding: "10px",
        backgroundColor: "rgb(61 150 251)",
        fontSize: "12px",
        border: "none",
        cursor: "pointer"
      }} type="submit" value="Send Message" onClick={handleClick}/>
    </form>
        </div>
    )
}
export default ContactForm;